/**=====================
    3.42 Wishlist CSS start
==========================**/
.wishlist {
	table {
		tr {
			td {
				svg {
					color: $dark-gray;
					cursor: pointer;
				}
			}
		}
	}
}
/**=====================
    3.42 Wishlist CSS Ends
==========================**/