/**=====================
  5.9 Box-layout CSS start
==========================**/
.box-layout {
  background-image: url(../images/other-images/boxbg.jpg);
  background-blend-mode: overlay;
  background-color: rgba($white, 0.5);
  // dashboard-2
  .sales-overview {
    .common-box {
      @media only screen and (min-width:1275px) {
        padding-left: 28px;
        padding-top: 33px;
      }
      @media (min-width:1245px) and (max-width:1274px) {
        padding-left: 28px;
        padding-top: 30px;
      }
      @media (min-width:1220px) and (max-width:1244px) {
        padding-left: 35px;
        padding-top: 28px;
      }
      @media (min-width:1200px) and (max-width:1219px) {
        padding-left: 42px;
        padding-top: 26px;
      }
    }
  }
  @media only screen and (min-width: 1280px) {
    .page-wrapper {
      &.horizontal-wrapper,
      &.compact-wrapper {
        .page-body-wrapper {
          .page-body {
            // dashboard-1
            .default-dashboard {
              .pipeline-chart-container {
                .apexcharts-legend {
                  visibility: hidden;
                }
              }
              .col-xl-100 {
                flex: 0 0 auto;
                width: 100%;
              }
              .col-xl-70 {
                flex: 0 0 auto;
                width: 70%;
              }
              .col-xl-60 {
                flex: 0 0 auto;
                width: 60%;
              }
              .col-xl-50 {
                flex: 0 0 auto;
                width: 50%;
              }
              .col-xl-40 {
                flex: 0 0 auto;
                width: 40%;
              }
              .col-xl-30 {
                flex: 0 0 auto;
                width: 30%;
              }
              .order-xl-i {
                order: 1;
              }
              .order-xl-ii {
                order: 2;
              }
              .order-xl-iii {
                order: 3;
              }
              .pb-xl-20 {
                padding-bottom: 20px;
              }
              .pt-xl-20 {
                padding-top: 20px;
              }
            }
            .offer-banner {
              img {
                width: 10%;
                opacity: 1;
              }
            }
            .notification {
              ul {
                height: 300px;
                overflow-y: auto;
                padding-left: 5px;
              }
            }
            .to-do-list {
              ul {
                li {
                  .badge {
                    display: none;
                  }
                }
              }
            }
            .order-lists {
              .card-header-right-icon {
                display: none;
              }
            }
            .report-button {
              border-bottom: unset;
              justify-content: center;
              h5 {
                display: none;
              }
              .btn {
                padding: 8px 6px;
              }
            }
            .upgrade-card {
              .card-header {
                p {
                  @include ellipsis-box;
                  -webkit-line-clamp: 2;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                }
              }
            }
            .tread-cards {
              .card {
                .card-body {
                  h3 {
                    @include ellipsis-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    white-space: nowrap;
                    max-width: 90px;
                  }
                  span {
                    max-width: 95px;
                  }
                }
              }
            }
            .rate-column {
              display: none;
            }
            .member-datatable {
              tr {
                td,
                th {
                  &:nth-child(3) {
                    display: none;
                  }
                }
              }
            }
            .recent-orders {
              &.transaction-list {
                border-left: 1px solid var(--recent-dashed-border);
                border-right: 1px solid var(--recent-dashed-border);
              }
            }
            // dashboard-2
            .dashboard2 {
              .row-cols-xl-iii>* {
                flex: 0 0 auto;
                width: 33.3333333333%;
              }
              .col-xl-100 {
                flex: 0 0 auto;
                width: 100%;
              }
              .col-xl-80 {
                flex: 0 0 auto;
                width: 66.66666667%;
              }
              .col-xl-50 {
                flex: 0 0 auto;
                width: 50%;
              }
              .col-xl-40 {
                flex: 0 0 auto;
                width: 33.33333333%;
              }
              .order-xl-i {
                order: 1;
              }
              .order-xl-ii {
                order: 2;
              }
              .order-xl-iii {
                order: 3;
              }
              .order-xl-iv {
                order: 4;
              }
              .order-xl-v {
                order: 5;
              }
              .order-xl-vi {
                order: 5;
              }
              .order-xl-vii {
                order: 6;
              }
              .d-xl-none1 {
                display: none;
              }
            }
            .sales-overview {
              .rounded-circle2 {
                display: none;
              }
              h3 {
                font-size: 16px;
              }
              .sale-content {
                >div {
                  display: none !important;
                }
              }
            }
            .sales-transaction {
              display: none;
            }
            .deal-card {
              .deal-timer {
                gap: 10px;
                padding: 12px 0;
                li {
                  background: unset;
                  padding: 6px;
                  box-shadow: unset;
                }
              }
              .card-body {
                margin-bottom: -11px;
              }
              .swiper-pagination {
                bottom: 0px;
              }
            }
            .deal-animation {
              h2 {
                @include ellipsis-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: nowrap;
              }
              .deal-slider {
                margin-top: 38px;
                margin-left: 8px;
                .swiper {
                  img {
                    height: 100px;
                  }
                }
              }
            }
            // recent-order
            .project-datatable {
              &.order-tables {
                height: 120px;
                overflow-y: auto;
                .datatable-table {
                  thead,
                  tbody {
                    tr {
                      th,
                      td {
                        padding: 6px 8px;
                        &:nth-child(2) {
                          min-width: 150px;
                        }
                        &:nth-child(5) {
                          display: table-cell;
                          min-width: 100%;
                        }
                        &:nth-child(7) {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            // stock table
            .stock-table {
              height: 120px;
              overflow-y: auto;
              &.project-datatable {
                .datatable-wrapper {
                  .table {
                    tr {
                      th,
                      td {
                        padding: 8px 10px;
                        &:nth-child(3) {
                          display: table-cell;
                        }
                        &:nth-child(4) {
                          display: table-cell;
                        }
                        &:nth-child(6) {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            // store-card
            .store-card {
              h3 {
                @include ellipsis-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                white-space: normal;
                display: -webkit-box;
              }
              .btn {
                padding: 8px 9px;
                font-size: 14px;
              }
              .store-image {
                height: 124px;
                img {
                  top: -18px;
                  height: 142px;
                }
                span {
                  @include ellipsis-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  white-space: nowrap;
                }
                &::after {
                  height: 120px;
                }
              }
            }
            // orders 
            .order-card {
              h2 {
                font-size: 18px;
              }
              .delivery-image {
                display: none;
              }
              .order-content {
                position: absolute;
                top: 42px;
                right: 20px;
              }
            }
            .delivery-card {
              .card-header {
                .card-header-right-icon {
                  display: none;
                }
              }
              .delivery-content {
                .order-list {
                  li {
                    .order-content {
                      div:nth-of-type(2) {
                        span {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            .customer-profile {
              display: none;
            }
            .square-legend {
              >div {
                display: none;
                .legend-box {
                  gap: 3px;
                }
              }
            }
            .customer-rate {
              span {
                @include ellipsis-box;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
            .to-do-list {
              .activity-details {
                height: auto;
                overflow-y: auto;
              }
            }
            .delivery-content {
              ul {
                height: auto;
                overflow-y: auto;
              }
              .order-list {
                li {
                  .order-content {
                    div:nth-of-type(2) {
                      span {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            .activities-wrapper {
              &.to-do-list {
                ul {
                  li {
                    padding-bottom: 25px;
                    &:last-child {
                      padding-bottom: 0;
                    }
                  }
                }
              }
            }
            .visitor-customer {
              .card-body {
                padding-top: 0;
              }
              .weekly-visitor {
                margin-block: -20px;
              }
              .average-Visitors {
                gap: 8px
              }
            }
            // dashboard-3
            .welcome-card {
              .welcome-image {
                img {
                  display: none;
                }
              }
            }
            .notification {
              &.daily-task {
                ul {
                  height: auto;
                  overflow-y: unset;
                  padding-left: initial;
                }
              }
            }
            .earning-left-content {
              h2 {
                font-size: 18px;
              }
              >div {
                span {
                  +span {
                    display: none;
                  }
                }
              }
            }
            .project-datatable {
              .datatable-table {
                thead,
                tbody {
                  tr {
                    td,
                    th {
                      &:nth-child(3) {
                        display: none;
                      }
                      &:nth-child(5) {
                        display: none;
                      }
                      &:nth-child(7) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            .swiper {
              &.recent-slider {
                .recent-project-wrapper {
                  .recent-icon {
                    width: 40px;
                    height: 40px;
                    svg {
                      width: 20px;
                      height: 20px;
                    }
                  }
                  .recent-content {
                    .d-flex {
                      flex-wrap: wrap;
                    }
                    .customers {
                      ul {
                        li {
                          +li {
                            margin-left: -18%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .earning-tread {
              .card-body {
                .d-xxl-block {
                  display: none !important;
                }
              }
            }
            .daily-task {
              .task-content {
                a {
                  display: none;
                }
              }
            }
            .calendar-wrapper {
              .client-content {
                .client-sub-content {
                  li {
                    &:first-child {
                      display: none !important;
                    }
                    &:nth-child(2) {
                      border-left: none;
                      padding-left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          &.sidebar-list {
                            .badge {
                              right: 42px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sales-pipeline{
        .pipeline-chart-container {
          height: 171px;
          margin-top: -62px;
          margin-left: -32px;
          div {
            transform: scale(0.7);
          }
        }
      }
      //widget
      .sales-pipeline {
        &.pipeline-chart1 {
          .pipeline-chart-container {
            height: 171px;
            margin-top: -78px;
            div {
              transform: scale(0.7);
            }
          }
        }
        .apexcharts-legend {
          display: none !important;
        }
      }
    }
    .learning-filter {
      order: -1;
    }
    .md-sidebar {
      .md-sidebar-toggle {
        display: block;
      }
      .md-sidebar-aside {
        position: absolute;
        top: 40px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 3;
        width: 280px;
        &.open {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .job-sidebar {
      background-color: var(--white);
      padding: 20px;
    }
    .widget-1 {
      &.mb-xl-0 {
        margin-bottom: 25px !important;
      }
    }
    .box-order {
      .order-1 {
        order: 1 !important;
      }
    }
    .apache-cotainer-large,
    #line-visual,
    #line-visulH {
      >div,
      canvas {
        width: 100% !important;
      }
    }
    .page-wrapper {
      &.box-layout {
        padding-top: 40px;
        margin-top: 0 !important;
      }
      &.compact-wrapper {
        margin-top: $box-layout-space;
        .page-body-wrapper {
          .sidebar-wrapper {
            width: 230px;
            top: $box-layout-space;
            border-radius: 10px 0 0 10px;
            &.close_icon {
              ~footer {
                width: 1245px;
                left: 47%;
                padding-left: 40px;
              }
              ~.footer-fix {
                width: 1245px;
                left: 47%;
                padding-left: 40px;
              }
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 186px);
                }
              }
              &:hover {
                .sidebar-main {
                  .sidebar-links {
                    height: calc(100vh - 354px);
                  }
                }
              }
            }
            .logo-wrapper {
              box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
            }
            .sidebar-main {
              .sidebar-links {
                margin-top: 10px;
                margin-bottom: 20px;
                height: calc(100vh - 355px);
              }
            }
          }
          .page-body {
            min-height: calc(100vh - 130px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 220px);
            padding-bottom: 80px;
            margin-left: 230px;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: var(--light-primary-color);
            }
          }
        }
      }
      &.compact-sidebar {
        .page-header {
          padding-left: 148px;
        }
        .page-body-wrapper {
          margin-top: 40px;
          div.sidebar-wrapper {
            top: 40px;
            &.close_icon {
              display: none;
              transition: 0.5s;
            }
            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 166px);
                margin: 0;
                li {
                  .sidebar-submenu,
                  .mega-menu-container {
                    left: 470px;
                    top: 40px;
                    height: calc(100vh - 84px);
                  }
                  a {
                    &.active {
                      ~.sidebar-submenu,
                      ~.mega-menu-container {
                        animation: unset;
                      }
                    }
                  }
                }
              }
            }
          }
          .footer {
            margin-left: 0;
          }
          .page-body {
            min-height: calc(100vh - 90px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 170px);
            padding-bottom: 80px;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }
        }
        &.compact-small {
          .page-header {
            padding-left: 90px;
            &.close_icon {
              padding-left: 0;
            }
          }
          .page-body-wrapper {
            div.sidebar-wrapper {
              .sidebar-main {
                .sidebar-links {
                  li {
                    .sidebar-submenu,
                    .mega-menu-container {
                      left: 410px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.horizontal-wrapper {
        &:before {
          display: none;
        }
        .page-body-wrapper {
          margin-top: 40px;
          .page-body {
            margin-top: 80px;
            min-height: calc(100vh - 310px);
            max-height: calc(100vh - 230px);
            overflow: scroll;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }
          .footer {
            padding-left: 15px;
          }
          .sidebar-wrapper {
            width: 1280px;
            top: 76px;
            margin: 0;
            overflow-x: unset;
            position: relative;
            .sidebar-main {
              #sidebar-menu {
                left: 0%;
                transform: translate(0, 0);
                top: 125px;
              }
              .left-arrow,
              .right-arrow {
                bottom: 0;
              }
            }
          }
        }
        .page-header {
          padding-left: 0;
          .header-wrapper {
            border: 0;
          }
        }
      }
      &.modern-type {
        .page-header {
          padding-left: 0px;
        }
        .page-body-wrapper {
          .page-body {
            margin-left: 260px;
          }
          .sidebar-wrapper {
            top: 120px;
            .sidebar-main {
              .sidebar-links {
                height: calc(100vh - 311px);
              }
            }
            &.close_icon {
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 316px);
                }
              }
              ~footer {
                width: 1280px;
                left: 50%;
                margin-left: 0;
              }
            }
          }
          .footer {
            margin-left: 0;
          }
        }
      }
      .page-header {
        max-width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        padding-left: 230px;
        margin-top: $box-layout-space;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: unset;
        &.close_icon {
          padding-left: 76px;
          margin-left: auto;
          width: calc(100% - 0px);
          transition: $sidebar-transition;
        }
        .header-wrapper {
          border: transparent;
          border-radius: 10px 10px 0 0;
        }
      }
      &.material-type {
        .page-body-wrapper {
          margin-top: 0;
          .page-body {
            margin-top: 0;
          }
          .sidebar-wrapper {
            top: 0;
          }
        }
      }
      &.material-icon {
        .page-header {
          &.close_icon {
            margin-top: 40px !important;
          }
        }
        .page-body-wrapper {
          margin-top: 0;
          .page-body {
            top: 0;
            min-height: calc(100vh - 170px);
            padding-bottom: 40px;
          }
        }
      }
      &.advance-layout {
        .page-header {
          box-shadow: 0px 0px 10px rgba($black, 0.07);
        }
        .page-body-wrapper {
          margin-top: 0;
        }
      }
      .page-body-wrapper {
        width: 1280px;
        box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
        margin: 0 auto;
        overflow: hidden;
        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
        .active-order-table {
          max-width: 443px;
          overflow: auto;
          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
        .activity {
          .d-flex {
            .gradient-round {
              &.gradient-line-1 {
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }
              &.small-line {
                &:after {
                  height: 36px;
                  bottom: -43px;
                }
              }
              &.medium-line {
                &:after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }
        footer {
          width: 1280px;
          margin: 0 auto;
          padding-left: 230px;
          margin-bottom: 40px !important;
          position: fixed;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 0 0 10px;
        }
        .blockquote-footer {
          margin-left: 0;
          width: 100%;
        }
        .footer-fix {
          padding-left: 230px;
        }
        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
          img {
            opacity: 0.7;
          }
        }
        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
        .select2 {
          width: 901.781px;
        }
      }
      ul {
        &.close_icon {
          >li {
            label {
              padding: 0;
              &:after {
                display: none;
              }
            }
          }
        }
      }
      .bookmark {
        ul {
          margin-right: -1px;
        }
      }
      #batchDelete {
        .jsgrid-grid-header {
          .jsgrid-table {
            tr {
              th {
                .btn {
                  padding-left: 20px;
                  padding-right: 20px;
                }
              }
            }
          }
        }
      }
      .d-flex.bitcoin-graph {
        display: block !important;
        .top-bitcoin {
          display: inline-block;
          vertical-align: middle;
        }
        .flex-grow-1 {
          margin-top: 30px !important;
          .bitcoin-content {
            .bitcoin-numbers {
              h6 {
                font-size: 14PX;
              }
            }
            &.text-right {
              text-align: center !important;
            }
          }
        }
      }
      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }
      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .page-builder {
        .ge-canvas.ge-layout-desktop {
          margin-top: $box-layout-space;
        }
        .ge-addRowGroup {
          margin-bottom: 10px;
        }
      }
      .pricing-wrapper-card {
        padding: 50px 20px;
      }
      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris-daily {
          min-height: 430px;
        }
      }
      .flot-chart-placeholder {
        &#donut-color-chart-morris {
          min-height: 430px;
        }
      }
      .box-col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .box-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .box-col-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }
      .box-col-7 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .box-col-5 {
        flex: 0 0 40%;
        max-width: 40%;
      }
      .box-col-8 {
        flex: 0 0 70%;
        max-width: 70%;
      }
      .box-col-4e {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .box-col-4 {
        flex: 0 0 33.33%;
        max-width: 33%;
      }
      .box-col-8e {
        flex: 0 0 66.67%;
        max-width: 66.67%;
      }
      .box-col-none {
        display: none !important;
      }
      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 81px;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        transition: all linear 0.3s;
        &.show {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          transition: all linear 0.3s;
          padding-bottom: 25px;
        }
      }
      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }
      .social-app-profile {
        #friends {
          .box-col-4 {
            flex: 0 0 30%;
            max-width: 30%;
          }
        }
      }
      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }
        .row {
          .col-xl-6 {
            padding-left: 12px;
          }
          .col-xl-3 {
            +.col-xl-3 {
              padding-right: 12px;
            }
          }
        }
        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }
      }
      .todo {
        .notification-popup {
          right: 320px;
        }
      }
      .touchspin {
        padding: 0 10px;
      }
      .vertical-menu-main {
        width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        .mega-menu {
          width: 1050px !important;
          max-width: 1050px !important;
          left: -320px !important;
        }
      }
      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .main-timeline{
        ul{
          width: auto;
          height: auto !important;
          overflow: visible !important;
          &::before {
            left: 11px !important;
          }
        }
      }
      .timeline-lists.variation-timeline {
        width: auto;
        height: auto !important;
        overflow: visible !important;
        ul {
          width: auto;
          height: auto !important;
          overflow: visible !important;
        }
        &::before {
          left: 10px !important;
        }
      }
    }
  }
}
/**=====================
  5.9 Box-layout CSS Ends
==========================**/