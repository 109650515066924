/**=====================
    3.21 Internationalization CSS start
==========================**/
.hdg_main {
  position: relative;
}
.main {
  .langChoice {
    position: absolute;
    right: 40px;
    top: 12px;
    padding: 10px 12px;
    background-color: $transparent-color;
  }
}
// responsive css 
@media (max-width: 991px) {
  .main {
    .langChoice {
      right: 20px;
      top: 11px;
      padding: 8px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .main {
    .langChoice {
      top: 14px;
      padding: 4px 7px;
    }
  }
  .language-xs {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .language-header {
    padding-bottom: 60px !important;
  }
  .main {
    .langChoice {
      left: 0;
      margin: 0 auto;
      top: 50px;
      width: 30%;
      right: 0;
    }
  }
}
/**=====================
    3.21 Internationalization CSS End
==========================**/