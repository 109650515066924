/**=====================
   3.35 Projectlist CSS Start
==========================**/
.project-list {
	.row {
		margin: 15px;
	}
	button:focus {
		outline: none !important;
	}
	.theme-form .form-group {
		margin-bottom: 15px;
	}
	.border-tab.nav-tabs {
		.nav-item .nav-link {
			border: 1px solid transparent;
			padding: 5px 30px 5px 0;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}
	}
	.btn {
		margin-right: 5px;
		vertical-align: -12px;
		float: right;
		[dir="rtl"] & {
			float: left;
		}
		svg {
			vertical-align: middle;
			height: 16px;
		}
	}
	ul {
		margin-bottom: 0 !important;
		border-bottom: 0;
		li {
			svg {
				height: 18px;
				vertical-align: middle;
				margin-right: 5px;
			}
		}
	}
}
.tab-content {
	.row {
		>div.col-xl-4 {
			$show-numbers: true;
			@if $show-numbers {
				@for $i from 1 through 15 {
					&:nth-child(#{$i}) {
						animation-fill-mode: both;
						animation: fadeIncustom .5s linear #{$i}00000ms;
					}
				}
			}
		}
	}
}
.project-cards {
	.tab-content {
		margin-bottom: -30px;
	}
}
.project-box {
	border: 1px solid $light-semi-gray;
	border-radius: 5px;
	padding: 20px;
	background-color: lighten($light, 2%);
	margin-bottom: 20px;
	h4 {
		font-weight: 500 !important;
		margin-bottom: 8px;
		@media (max-width:400px) {
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box !important;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: normal;
			max-width: 135px;
		}
	}
	.badge {
		position: absolute;
		right: 32px;
		top: 15px;
		line-height: 13px;
	}
	.project-status {
		p {
			margin-bottom: 5px;
			font-weight: 800;
		}
	}
	.d-flex {
		margin-bottom: 15px;
		.flex-grow-1 {
			opacity: 0.5;
		}
	}
	.details {
		div {
			margin-bottom: 5px;
			opacity: 0.7;
		}
	}
}
/**=====================
   3.35 Projectlist CSS Ends
==========================**/