/**=====================
  4.3 Update CSS Start
==========================**/
@import "utils/variables";
// jsgrid css
.jsgrid-filter-row {
    select,
    input {
        padding: 6px 10px;
        border: 1px solid $horizontal-border-color;
        background: $white;
    }
}
// tooltip css start
.tooltip {
    &.bs-tooltip-top {
        .tooltip-arrow {
            &:before {
                border-top-color: $light-primary;
            }
        }
    }
    &.bs-tooltip-bottom {
        .tooltip-arrow {
            &:before {
                border-bottom-color: $light-primary;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-start {
        .tooltip-arrow {
            &:before {
                border-left-color: $light-primary;
                border-top-color: transparent;
            }
        }
    }
    &.bs-tooltip-end {
        .tooltip-arrow {
            &:before {
                border-right-color: $light-primary;
                border-top-color: transparent;
            }
        }
    }
    .tooltip-inner {
        background-color: $light-primary;
        color: var(--theme-default);
    }
    .tooltip-arrow {
        &:before {
            border-top-color: $light-primary;
        }
    }
}
// tooltip css end
// dropdown css start
.dropdown-basic {
    .dropdown {
        .dropdown-content {
            a {
                padding: 6px 16px;
                color: $dark-editor-document;
                opacity: 0.6;
                font-size: 13px;
                border-top: 1px solid $light-semi-gray;
                background: $white;
                &:hover {
                    background-color: $white;
                }
            }
            .dropdown-header {
                padding: 8px 16px;
                font-weight: 400;
                color: $dark-color;
                font-size: 13px;
            }
        }
    }
    .dropup {
        .dropup-content {
            top: auto;
        }
    }
}
// dropdown css end
// accordian css start
.default-according {
    .card {
        .card-header {
            padding: 0;
            border: none;
            border-radius: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            &.bg-primary,
            &.bg-secondary {
                .btn {
                    border-color: $transparent-color;
                }
            }
            i {
                position: initial;
                font-size: 20px;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 5px;
                [dir="rtl"] & {
                    margin-right: unset;
                    margin-left: 5px;
                }
            }
            h5 {
                margin-top: 0;
            }
            .btn-link {
                padding: 12px 18px;
                gap: 8px;
                letter-spacing: 0.7px;
                font-family: Inter;
                border: 1px solid $light-semi-gray;
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                [dir="rtl"] & {
                    text-align: right;
                }
                @media (max-width: 575px) {
                    padding: 12px 15px;
                }
            }
        }
        .card-body {
            padding: 15px 20px;
            line-height: 1.8;
            font-size: 13px;
            border-color: $light-semi-gray;
            border-radius: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            color: $dark-color;
        }
    }
}
// accordian css end
// tab-bootstrap css start
.nav-tabs {
    border-bottom-color: $light-semi-gray;
    .nav-bottom {
        .nav-item {
            .nav-link.active {
                border-color: $light-semi-gray;
            }
        }
    }
}
.nav-tabs {
    .nav-item {
        &.show {
            .nav-link {
                font-weight: 500;
            }
        }
    }
    .nav-link {
        color: $dark-color;
        &.active {
            font-weight: 500;
        }
    }
    &.nav-bottom {
        .nav-item {
            .nav-link {
                &.active {
                    border-color: $light-gray $light-gray #fff;
                }
            }
        }
    }
}
.dropdown-menu {
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    border: none;
    padding: 0;
    z-index: 2;
    .dropdown-item {
        color: #2b2b2b;
        opacity: 0.9;
        font-size: 13px;
        padding: 6px 12px;
        border-top: 1px solid $light-semi-gray;
        background: var(--white);
        &:hover {
            background-color: var(--white);
        }
    }
    .dropdown-divider {
        margin: 0;
        border-top: 1px solid $gray-60;
    }
}
// tab-bootstrap css end
.border-tab {
    .nav-tabs {
        .nav-item {
            .nav-link {
                &.active {
                    border-bottom: 2px solid var(--theme-default);
                }
                &.show {
                    border-bottom: 2px solid var(--theme-default);
                }
                &:focus {
                    border-bottom: 2px solid var(--theme-default);
                }
            }
        }
    }
}
.timeliny {
    .timeliny-dot::before {
        font-size: $btn-lg-font-size;
    }
}
.component {
    .input-group {
        .btn {
            line-height: 32px;
            text-transform: capitalize;
        }
    }
}
.login-card {
    .login-main {
        .theme-form {
            label {
                font-size: $body-font-size;
            }
            .show-hide {
                top: 50%;
            }
        }
    }
}
// faq css end
.job-filter {
    .faq-form {
        .form-control {
            font-size: 14px;
        }
        .search-icon {
            width: 16px;
            height: 16px;
        }
    }
}
// editor css
.note-btn-group {
    .dropdown-toggle {
        &.show {
            ~.dropdown-menu {
                display: block;
                top: 30px;
            }
        }
    }
}
// owl-carousel css
.carousel {
    .carousel-indicators {
        margin-bottom: 3px;
    }
}
.mouse-wheel {
    .owl-stage-outer {
        .owl-stage {
            width: 5600px !important;
            .owl-item {
                width: 225.333px !important;
            }
        }
    }
}
.carousel-item {
    .carousel-caption {
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 26px 10px;
        background-color: rgba($dark-card-background, 0.51);
    }
    .carousel-opacity {
        background-color: rgba($light-color, 0.51);
    }
}
.carousel {
    .carousel-control-prev-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }
    .carousel-control-next-icon {
        width: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
        height: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    }
}
// Rating 
.rating-header {
    h4 {
        [dir="rtl"] & {
            direction: ltr;
        }
    }
}
// Animated modal
.animate-img {
    .animate-widget {
        img {
            width: 100%;
            height: 480px;
            margin: 0 auto;
            object-fit: cover;
            @media (max-width:575px) {
                height: 300px;
            }
        }
    }
}
.modal-popup {
    &.modal {
        top: 20%;
        left: 50%;
        transform: translate(-50%, 0);
        .modal-dialog {
            .theme-close {
                width: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                height: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}
.animated-modal {
    .form-select {
        @media (max-width:442px) {
            width: 100% !important;
        }
        &:focus {
            box-shadow: unset;
            border-color: unset;
        }
    }
}
// TO-DO scss
.task-container {
    .badge {
        line-height: 13px;
    }
}
// Add-post scss
.add-post {
    form {
        .form-control {
            &:focus {
                border: 1px solid var(--recent-dashed-border);
                box-shadow: unset;
            }
        }
    }
}
// Modal page
.modal {
    .modal-body {
        .card {
            box-shadow: none !important;
        }
    }
}
.modal-dialog {
    .modal-content {
        .modal-body {
            p {
                a {
                    margin-right: 0;
                }
            }
        }
    }
}
.alert-theme {
    button {
        top: 50% !important;
        transform: translate(0, -50%);
        right: 20px !important;
        color: var(--theme-default);
        [dir="rtl"] & {
            right: unset !important;
            left: 20px !important;
        }
    }
    span {
        +span {
            +span {
                background-color: $white;
                color: var(--theme-default);
                border-radius: 4px;
                box-shadow: 0 2px 14px -3px rgba($black, 0.2);
            }
        }
    }
    .close {
        color: var(--theme-default);
    }
    i {
        padding: 20px;
        padding-right: 0 !important;
        margin-right: 20px !important;
    }
    .progress {
        margin-top: -2px !important;
        height: 2px;
        width: 100%;
        .progress-bar {
            background-color: var(--theme-default);
        }
    }
    @media (max-width: 480px) {
        min-width: 260px !important;
    }
}
.ace-editor {
    height: 400px;
    width: 100%;
}
.editor-toolbar {
    width: 100% !important;
    &.fullscreen {
        z-index: 100;
    }
}
.CodeMirror {
    top: 0 !important;
}
.CodeMirror-fullscreen {
    top: 50px !important;
}
// rangeslider
.irs-from,
.irs-to,
.irs-single {
    background: var(--theme-default);
    &:after {
        border-top-color: var(--theme-default);
    }
}
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
    background-color: var(--theme-default);
}
// sweetalert css
.swal-button {
    background-color: var(--theme-default);
    &:active {
        background-color: darken($primary-color, 5%);
    }
}
.swal-button--cancel {
    background-color: #EFEFEE !important;
}
.clipboaard-container {
    h6 {
        line-height: 1.6;
        font-size: $paragraph-font-size;
    }
}
fieldset {
    .bootstrap-touchspin-postfix,
    .bootstrap-touchspin-prefix {
        border-radius: 0;
        padding: .4rem .75rem;
    }
    .input-group-lg {
        .touchspin {
            height: 50px;
        }
    }
}
.f1 .f1-steps .f1-step .f1-step-icon {
    padding: 12px;
}
.form-inline .form-group {
    display: flex;
}
.input-group-text {
    i {
        line-height: 1.5;
    }
}
// animated modal css
.animated-modal {
    .form-select {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}
.card-wrapper {
    padding: 14px;
    dl {
        dt {
            font-weight: 500;
        }
        dd {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.starter-main {
    .alert-primary {
        &.inverse {
            padding: 13px 20px 13px 65px;
            border-color: var(--theme-default);
            color: $dark-color;
            [dir="rtl"] & {
                padding: 13px 65px 13px 20px;
            }
            &:before {
                content: "";
                position: absolute;
                left: 46px;
                width: 15px;
                height: 15px;
                top: 50%;
                background-color: var(--theme-default);
                transform: translateY(-50%) rotate(45deg);
                [dir="rtl"] & {
                    left: unset;
                    right: 46px;
                }
            }
            i {
                padding: 17px 20px;
                display: flex;
                align-items: center;
                background-color: var(--theme-default);
                color: $white;
                border-radius: 3px 0 0 3px;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                [dir="rtl"] & {
                    right: 0;
                    left: unset;
                }
            }
        }
    }
    .alert {
        background-color: rgba(0, 157, 181, 0.4);
    }
    .card-body {
        ul {
            margin-bottom: 14px;
        }
        dl {
            dd {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
// owl carousel css
.owl-theme {
    .owl-dots {
        button {
            span {
                @media (max-width:575px) {
                    margin: 5px !important;
                }
            }
        }
        .owl-dot {
            span {
                background: lighten($primary-color, 15%);
            }
            &.active,
            &:hover {
                span {
                    background: var(--theme-default);
                }
            }
        }
    }
    .owl-nav {
        &.disabled {
            &+.owl-dots {
                margin-bottom: -10px;
                margin-top: 25px;
            }
        }
    }
}
// input-mask
.input-mask {
    .card-wrapper {
        .form-control {
            font-size: 14px;
        }
    }
}
// Todo
.todo {
    .todo-list-container {
        .mark-all-tasks {
            top: 12px !important;
        }
    }
}
.todo {
    .todo-list-wrapper {
        .mark-all-tasks {
            .mark-all-btn#mark-all-incomplete {
                .action-box {
                    border-color: $success-color !important;
                }
            }
            .mark-all-btn#mark-all-incomplete {
                color: $success-color !important;
            }
            .mark-all-btn#mark-all-finished {
                color: $danger-color !important;
            }
        }
        .task {
            .task-container {
                .task-label {
                    font-size: 16px !important;
                    color: var(--body-light-font-color) !important;
                }
            }
        }
    }
    .action-box {
        &.completed {
            background-color: $danger-color !important;
            border-color: $danger-color !important;
        }
    }
    .todo-list-footer {
        .new-task-wrapper {
            &.visible {
                margin-bottom: 16px;
            }
        }
    }
}
// Dragable-card
.ui-sortable {
    .list-group {
        .list-group-item {
            &.active {
                z-index: 0 !important;
            }
        }
    }
}
//widget
#chart-widget1,
#chart-widget2,
#chart-widget3{
    margin-bottom: -15px;
}
.select2-container--default {
	.select2-selection--single {
		.select2-selection__arrow {
			top: 12px; 
		}
	}
}

.user-profile {
	.nav-tabs {
		.nav-link {
			&:focus {
				isolation: auto;
			}
		}
	}
}
.table{
    .alert{
      &.inverse{
        background-color: rgba(0, 157, 181, 0.8) !important;
      }
    }
  }
  .code-box-copy__btn{
    background-color: #009db54a;
    border: 1px solid #009db54a;
  }
  .starter-kit-fix .page-body {
    margin-bottom: 52px;
}
input[type="password"]{
    padding: 12px 60px 12px 12px !important; 
}
.ace_print-margin{
    display: none;
}
.pswp__caption__center{
    h4{
        color: $body-color;
    }
}
/**=====================
    4.3 Update CSS Ends
==========================**/