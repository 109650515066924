// Invoice 
.invoice {
    h4,
    h6 {
        font-weight: 600;
    }
    p {
        letter-spacing: 0.6px;
    }
}
.invoice-1 {
    body {
        font-family: Inter, sans-serif;
        display: block;
        color: #000248;
    }
    .table-wrapper {
        margin: 0 auto;
    }
    h2 {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
    }
    h6 {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
    }
    span {
        line-height: 1.5;
        font-weight: 400;
    }
    .banner-image {
        margin: 13px 0 10px;
    }
    .order-details td span {
        margin-bottom: -4px;
        display: block;
    }
}
.invoice-2 {
    .card-body {
        >table {
            display: block;
            >tbody {
                display: block;
                >tr {
                    display: block;
                    >td {
                        display: block;
                    }
                }
            }
        }
    }
}