/**=====================
    3.20 Helper CSS start
==========================**/
.helper-common-box {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    gap: 8px;
    &:last-child {
        margin-bottom: 0;
    }
    &.helper-p-wrapper {
        gap: 12px;
        margin-bottom: 0;
        flex-wrap: wrap;
    }
}
.border-wrapper {
    padding: 20px;
}
.helper-box {
    width: 40px;
    height: 40px;
    border-radius: 25%;
    &.helper-text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
    }
}
.helper-radius {
    width: 60px;
    height: 30px;
}
.fill-wrapper {
    background-color: $light-color;
}
.gradient-border {
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 1200px) {
        gap: calc(13px + (22 - 13) * ((100vw - 320px) / (1200 - 320)));
    }
}
.radius-wrapper {
    background-color: var(--course-light-btn);
}
.font-wrapper {
    display: flex;
    align-items: center;
    padding: 12px 20px;
}
.helper-padding {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.helper-p-box {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        color: var(--body-font-color);
    }
}
.common-p-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
}
/**=====================
    3.20 Helper CSS end
==========================**/