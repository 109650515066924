/**=====================
	4.1. Dark CSS Start
==========================**/
body {
	&.dark-only {
		color: $dark-all-font-color;
		background-color: $dark-body-background;
		.card {
			--bs-card-title-color: var(--body-font-color);
			--bs-card-subtitle-color: var(--body-font-color);
			--bs-card-cap-color: var(--body-font-color);
			--bs-card-color: var(--body-font-color);
			--bs-body-color: var(--body-font-color);
		}
		.modal {
			.modal-header {
				--bs-heading-color: $dark-all-font-color;
			}
		}
		.modal {
			--bs-modal-color: rgba(255, 255, 255, 0.2);
			--bs-modal-footer-bg: $dark-card-background;
		}
		.breadcrumb {
			--bs-breadcrumb-bg: transparent;
		}
		.toast {
			--bs-toast-color: var(--body-font-color);
		}
		.form-control {
			background-color: $dark-card-background;
			border-color: $dark-card-border;
			color: $dark-small-font-color;
			&::placeholder {
				color: $light-all-font-color;
			}
		}
		// ========================================== start===================================
		input[type=file] {
			&::file-selector-button {
				color: $dark-all-font-color;
				background-color: $dark-card-background !important;
				&:hover {
					color: $dark-all-font-color !important;
					background-color: $dark-card-background !important;
				}
			}
		}
		.form-floating {
			.form-control {
				&::placeholder {
					color: $dark-body-background;
				}
			}
		}
		.main-radio-toggle {
			.btn-check {
				&:checked {
					+.btn {
						color: $dark-all-font-color;
					}
				}
			}
			.btn {
				color: $dark-card-background;
			}
		}
		.apexcharts-tooltip.light {
			.apexcharts-tooltip-title {
				background: $dark-body-background;
				color: $white;
			}
		}
		.apexcharts-tooltip.apexcharts-theme-light {
			border: 1px solid $dark-card-border;
			background: $dark-body-background;
		}
		.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
			border: 1px solid $dark-card-border;
			background: $dark-body-background;
			color: $dark-all-font-color;
		}
		.apexcharts-tooltip-series-group {
			color: $dark-all-font-color !important;
		}
		.apexcharts-yaxistooltip,
		.apexcharts-xaxistooltip {
			color: $dark-all-font-color;
			background: $dark-body-background;
			border: 1px solid $dark-card-border;
		}
		.apexcharts-yaxistooltip-left {
			&::before,
			&::after {
				border-left-color: $dark-card-border;
			}
		}
		.apexcharts-xaxistooltip-bottom {
			&::before,
			&::after {
				border-bottom-color: $dark-card-border;
			}
		}
		.bottom-content {
			.apexcharts-canvas {
				span {
					color: $dark-all-font-color;
				}
			}
		}
		.apexcharts-canvas {
			.apexcharts-tooltip {
				border: 1px solid $dark-card-border;
				.apexcharts-tooltip-title {
					margin-bottom: 0;
				}
				.apexcharts-tooltip-series-group {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
			.apexcharts-yaxis {
				text {
					fill: $dark-small-font-color;
				}
			}
		}
		.apexcharts-xaxistooltip {
			background-color: $dark-body-background;
			color: $dark-all-font-color;
			border: 1px solid $dark-card-border;
		}
		.apexcharts-xaxistooltip-bottom:before {
			border-bottom-color: $dark-card-border;
		}
		.apexcharts-xaxistooltip-bottom:after {
			border-bottom-color: $dark-card-border;
		}
		.apexcharts-tooltip.apexcharts-theme-light {
			border: 1px solid $dark-card-border;
			background: $dark-body-background;
			.apexcharts-tooltip-text {
				color: $dark-small-font-color;
			}
			.apexcharts-tooltip-title {
				background: $dark-card-background;
				border-bottom: 1px solid $dark-card-border;
				color: $dark-small-font-color;
			}
		}
		.apexcharts-yaxis {
			text {
				color: $dark-all-font-color;
			}
		}
		.theme-form input[type=text] {
			background-color: $dark-body-background;
			border: 1px solid $dark-card-border;
		}
		.theme-form textarea {
			background-color: $dark-body-background;
		}
		// =====================================End======================-=========
		.page-body {
			.bg-light {
				background-color: $dark-body-background !important;
			}
		}
		.wizard-4 {
			.step-container {
				background-color: $dark-card-background;
				border-color: $dark-card-border;
			}
			.login-card {
				.login-main {
					background-color: $dark-body-background;
				}
			}
		}
		.total-balance {
			background-blend-mode: overlay;
		}
		.icon-state {
			.switch-state {
				&:after {
					color: $dark-all-font-color;
				}
			}
		}
		.social-widget {
			.social-icons {
				box-shadow: 0px 42.1092px 27.8569px $dark-body-background, 0px 8.90772px 7.12618px $dark-body-background, 0px 2.02448px 3.44162px $dark-body-background;
			}
		}
		.calendar-basic {
			.md-sidebar-aside {
				background: $dark-card-background;
			}
			#external-events {
				border-color: $dark-card-border;
			}
			.calendar-default {
				.fc {
					.fc-daygrid-day-number,
					.fc-col-header-cell-cushion {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.clockpicker-popover {
			.popover-title {
				background-color: $dark-card-background;
			}
			&.bottom {
				>.arrow {
					border-bottom-color: $dark-body-background;
					&:after {
						border-bottom-color: $dark-card-background;
					}
				}
			}
			&.top {
				>.arrow {
					border-top-color: $dark-body-background;
					&::after {
						border-top-color: $dark-card-background;
					}
				}
			}
			&.left {
				>.arrow {
					border-left-color: $dark-body-background;
					&::after {
						border-left-color: $dark-card-background;
					}
				}
			}
			.popover-title {
				border-bottom-color: $dark-card-background;
			}
		}
		.fc-theme-standard {
			.fc-scrollgrid,
			.fc-list {
				border-color: $dark-card-border;
			}
			.fc-list-day-cushion {
				background-color: $dark-body-background;
			}
		}
		.fc {
			.fc-list-event {
				&:hover {
					td {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.view-container {
			.apexcharts-canvas {
				.apexcharts-point-annotations {
					circle {
						outline: 25px solid #3e3d7259;
					}
				}
			}
		}
		.btn {
			&.badge-light-primary {
				&:hover {
					color: rgba($white, 0.6);
				}
			}
		}
		.portfolio-chart-container {
			.apexcharts-canvas {
				.apexcharts-radialbar {
					image {
						display: none;
					}
				}
			}
		}
		// online course dashboard  css end
		.page-wrapper {
			&.compact-wrapper {
				.page-header {
					&::before {
						background-blend-mode: color-burn;
					}
				}
			}
		}
		.f-light {
			color: $dark-all-font-color;
		}
		.apexcharts-tooltip.light {
			.apexcharts-tooltip-title {
				background: $dark-card-background;
				color: $white;
			}
		}
		.card {
			background-color: $dark-card-background;
		}
		.ecommerce-widget {
			border: 1px solid $dark-card-border !important;
		}
		.btn-light {
			color: $dark-all-font-color !important;
		}
		.form-select {
			color: $dark-all-font-color;
		}
		.add-project {
			.text-inherit {
				color: $dark-all-font-color;
			}
		}
		.knowledgebase-search {
			color: $theme-body-font-color;
			.form-control-plaintext {
				color: $theme-body-font-color;
			}
		}
		// Toasts 
		.toast {
			border: 1px solid $dark-card-border;
			.toast-header {
				background-color: $dark-card-background;
				color: $dark-all-font-color;
			}
			.toast-dark {
				background-color: $dark-card-background;
				color: $dark-all-font-color !important;
			}
		}
		.btn-close {
			filter: brightness(0.8) invert(1);
		}
		.prooduct-details-box {
			.d-flex {
				border: 1px solid $dark-card-border;
			}
		}
		.product-price {
			del {
				color: $dark-small-font-color;
			}
		}
		.ProfileCard {
			border: 1px solid $dark-card-border !important;
		}
		.form-control-plaintext {
			color: $dark-all-font-color;
		}
		.form-select {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
		}
		.box-layout {
			background-color: $dark-body-background;
			.page-wrapper,
			&.page-wrapper {
				.page-header {
					.header-wrapper {
						border: 1px solid $dark-border-color;
					}
				}
			}
		}
		.form-check-input {
			background-color: $dark-body-background;
			border-color: $dark-card-border;
		}
		// Radio and checkbox control
		.checkbox-wrapper,
		.radio-wrapper {
			li {
				.form-check-input {
					background-color: transparent;
				}
			}
		}
		.switch-state {
			&:before {
				background-color: $dark-card-background;
			}
		}
		// tooltip css start
		.tooltip {
			&.bs-tooltip-top {
				.tooltip-arrow {
					&:before {
						border-top-color: $dark-card-inbox;
					}
				}
			}
			&.bs-tooltip-bottom {
				.tooltip-arrow {
					&:before {
						border-bottom-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}
			&.bs-tooltip-start {
				.tooltip-arrow {
					&:before {
						border-left-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}
			&.bs-tooltip-end {
				.tooltip-arrow {
					&:before {
						border-right-color: $dark-card-inbox;
						border-top-color: $transparent-color;
					}
				}
			}
			.tooltip-inner {
				background-color: $dark-card-inbox;
				color: $white;
			}
			.tooltip-arrow {
				&:before {
					border-top-color: $dark-card-inbox;
				}
			}
		}
		// tooltip css end
		.page-wrapper {
			&.only-body {
				.page-body-wrapper {
					.page-title {
						>.row {
							.col-6 {
								&:first-child {
									h6 {
										color: rgba($dark-all-font-color, 0.5);
									}
								}
							}
						}
					}
				}
				.page-header {
					.header-wrapper {
						.search-full {
							.form-group {
								.Typeahead {
									.u-posRelative {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
				}
			}
			.page-header {
				.header-wrapper {
					.nav-right {
						.profile-dropdown li span {
							color: $white;
						}
						.flip-card .flip-card-inner {
							.back {
								background-color: $dark-body-background !important;
								.flip-back-content {
									input {
										background-color: $dark-body-background;
										border: 1px solid $dark-card-border;
										color: $dark-all-font-color;
									}
								}
								li {
									&:last-child {
										border-top: 1px solid $dark-card-border;
									}
								}
							}
							.front {
								background-color: $dark-body-background !important;
								svg {
									stroke: $white;
								}
							}
						}
						.cart-dropdown {
							.total {
								background-color: $dark-card-background;
							}
							.qty-box {
								.input-group {
									.btn {
										color: rgba($white, 0.4);
										border-color: $dark-card-background !important;
										background-color: $dark-card-background !important;
									}
								}
							}
						}
					}
				}
			}
		}
		.qty-box {
			input {
				color: rgba($white, 0.5);
			}
		}
		.customizer-contain {
			color: $dark-body-background;
			.customizer-body {
				.main-layout {
					.box-layout {
						background-color: $white;
					}
				}
			}
		}
		.login-dark {
			background-color: $dark-card-background;
			background-blend-mode: overlay;
			.login-main {
				background-color: $dark-card-inbox;
			}
		}
		.login-card {
			.btn-showcase .btn {
				background-color: $dark-card-background !important;
				color: $white;
				border-color: $dark-card-background !important;
			}
			.login-main {
				box-shadow: 0 0 37px rgba($white, 0.05);
				.theme-form {
					input {
						background-color: $dark-card-background !important;
					}
					.or {
						&:before {
							background-color: $dark-card-background;
						}
					}
					.checkbox label::before {
						background-color: $dark-card-background !important;
						border-color: $dark-card-background !important;
					}
				}
			}
		}
		.H_ui {
			~div {
				background-color: $dark-body-background !important;
			}
		}
		.swal-icon--success:after,
		.swal-icon--success:before {
			background: $dark-card-background;
		}
		.swal-icon--success__hide-corners {
			background-color: $dark-card-background;
		}
		.light-font {
			color: rgba(255, 255, 255, 70%);
		}
		.page-link {
			background-color: $dark-body-background;
			border: 1px solid $dark-card-border;
		}
		.b-r-light {
			border-right: 1px solid $dark-card-border !important;
		}
		.history-details {
			.d-flex {
				border-top: 1px solid $dark-card-border;
			}
		}
		.modal-header {
			border-bottom: 1px solid $dark-card-border;
		}
		#right-history {
			background-color: $dark-card-background;
			box-shadow: 0 0 9px $dark-body-background;
			h6 {
				span {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.ProfileCard {
			&:hover {
				color: $dark-all-font-color;
				background-color: $dark-card-background;
			}
		}
		.translate_wrapper.active .more_lang {
			background-color: $bg-dark-color;
			&:before {
				border-bottom: 7px solid $bg-dark-color;
			}
			.lang {
				border-bottom: 1px solid $dark-body-background;
			}
		}
		.toggle-sidebar {
			svg {
				stroke: var(--theme-default);
			}
		}
		.page-wrapper {
			&.horizontal-wrapper {
				.page-header {
					background-color: $dark-card-background;
				}
			}
			.page-body-wrapper {
				background-color: $dark-body-background;
				.page-title {
					background-color: $dark-card-background;
					border-bottom: 1px solid $dark-card-border;
					box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 40px;
				}
				div.sidebar-wrapper {
					.sidebar-main {
						.sidebar-links {
							.simplebar-wrapper {
								.simplebar-mask {
									.simplebar-content-wrapper {
										.simplebar-content {
											>li {
												a {
													svg {
														&.stroke-icon {
															stroke: $white;
														}
													}
													&.active {
														svg {
															&.stroke-icon {
																stroke: var(--theme-default);
															}
															&.fill-icon {
																fill: var(--theme-default);
																stroke: transparent;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.sidebar-main {
					.sidebar-links {
						.sidebar-link.active {
							svg {
								&.stroke-icon {
									stroke: var(--theme-default);
								}
							}
						}
						li {
							a {
								span {
									color: $dark-all-font-color;
								}
							}
						}
						.simplebar-wrapper {
							.simplebar-mask {
								.simplebar-content-wrapper {
									.simplebar-content {
										>li {
											.mega-menu-container {
												.mega-box {
													.link-section {
														.submenu-title {
															h5 {
																color: $dark-all-font-color;
															}
														}
														.submenu-content {
															&.opensubmegamenu {
																li {
																	a {
																		color: $dark-all-font-color;
																		font-weight: 400;
																	}
																}
															}
														}
													}
												}
												&::after {
													background-color: $light-all-font-color;
												}
											}
											.sidebar-submenu {
												li {
													a {
														color: $dark-all-font-color;
														&.active {
															color: var(--theme-default);
														}
													}
												}
											}
										}
									}
								}
							}
						}
						.sidebar-list {
							ul.sidebar-submenu {
								li {
									a {
										span {
											color: $dark-all-font-color;
										}
										&.active {
											color: var(--theme-default);
										}
									}
								}
							}
						}
					}
				}
			}
			&.modern-type {
				.page-body-wrapper {
					.page-title {
						background-color: transparent;
						border: none;
					}
				}
			}
			.sidebar-main-title {
				p {
					color: $dark-all-font-color;
				}
			}
			&.compact-wrapper {
				.page-body-wrapper {
					div.sidebar-wrapper {
						background-color: $dark-card-background;
						.sidebar-main {
							.sidebar-links {
								.sidebar-link.active {
									svg {
										stroke: var(--theme-default);
									}
								}
								li {
									a {
										span {
											color: $dark-all-font-color;
										}
									}
								}
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													.mega-menu-container {
														.mega-box {
															.link-section {
																.submenu-title {
																	h5 {
																		color: $dark-all-font-color;
																	}
																}
																.submenu-content {
																	&.opensubmegamenu {
																		li {
																			a {
																				color: $dark-all-font-color;
																				font-weight: 400;
																			}
																		}
																	}
																}
															}
														}
														&::after {
															background-color: $light-all-font-color;
														}
													}
													.sidebar-submenu {
														li {
															a {
																color: $dark-all-font-color;
																&.active {
																	color: var(--theme-default);
																}
															}
														}
													}
												}
											}
										}
									}
								}
								.sidebar-list {
									ul.sidebar-submenu {
										li {
											a {
												span {
													color: $dark-all-font-color;
												}
												&.active {
													color: var(--theme-default);
												}
											}
										}
									}
								}
							}
						}
					}
					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}
			&.horizontal-wrapper {
				.page-body-wrapper {
					.sidebar-wrapper {
						background: $dark-card-background;
						border-top: 1px solid $dark-card-border;
						.sidebar-main {
							.sidebar-links {
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													>a {
														color: $dark-all-font-color;
													}
													.sidebar-submenu {
														background: $dark-card-background;
														li {
															a {
																color: $dark-all-font-color;
																&.active,
																&:hover {
																	color: var(--theme-default);
																}
															}
															.nav-sub-childmenu {
																background: $dark-card-background;
															}
														}
													}
													.mega-menu-container {
														background: $dark-card-background;
														.mega-box {
															.link-section {
																.submenu-content {
																	li {
																		a {
																			color: $dark-all-font-color;
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
							.left-arrow,
							.right-arrow {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
			&.compact-sidebar {
				.page-body-wrapper {
					div {
						&.sidebar-wrapper {
							>div {
								background: $dark-card-background;
							}
							.sidebar-main {
								.sidebar-links {
									li {
										.sidebar-title {
											border-bottom: 1px solid $dark-card-border;
										}
										a {
											span {
												color: $dark-all-font-color;
											}
											svg {
												stroke: $dark-all-font-color;
											}
										}
										.sidebar-submenu,
										.mega-menu-container {
											background-color: $dark-card-background;
											li {
												a {
													border-bottom: 1px solid $dark-card-border;
												}
											}
										}
									}
									.simplebar-wrapper {
										.simplebar-mask {
											.simplebar-content-wrapper {
												.simplebar-content {
													>li {
														.sidebar-link {
															&.active {
																background-color: rgba($primary-color, 0.2);
																span {
																	color: var(--theme-default);
																}
																svg {
																	color: var(--theme-default);
																	stroke: var(--theme-default);
																}
															}
														}
														.mega-menu-container {
															.mega-box {
																.link-section {
																	.submenu-title {
																		h5 {
																			color: $dark-all-font-color;
																		}
																	}
																	.submenu-content {
																		&.opensubmegamenu {
																			li {
																				a {
																					color: $dark-all-font-color;
																					font-weight: 400;
																				}
																			}
																		}
																	}
																}
															}
															&::after {
																background-color: $light-all-font-color;
															}
														}
														.sidebar-submenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
										}
									}
									.sidebar-list {
										ul.sidebar-submenu {
											li {
												a {
													span {
														color: $dark-all-font-color;
													}
													&.active {
														color: var(--theme-default);
													}
												}
											}
										}
									}
								}
							}
						}
					}
					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}
			&.material-type {
				.page-header {
					.header-wrapper {
						border-radius: 15px 15px 0 0;
					}
				}
			}
			// material icon css
			&.material-icon {
				.page-header {
					.header-wrapper {
						background-color: $transparent-color;
						.nav-right {
							.language-nav {
								.more_lang {
									color: $white;
								}
							}
							.onhover-show-div {
								color: $white;
							}
							.flip-card {
								.flip-card-inner {
									.back {
										.flip-back-content {
											input {
												border: 1px solid $dark-border-color;
												background: $dark-body-background;
												color: $white;
											}
										}
									}
								}
							}
						}
					}
					.header-logo-wrapper {
						background-color: $transparent-color;
					}
				}
				.page-body-wrapper {
					.page-body {
						background-color: $transparent-color;
					}
				}
			}
			&.advance-layout {
				background-color: $dark-body-background;
				.page-header {
					.header-wrapper {
						background: $transparent-color;
						.header-logo-wrapper {
							background: $transparent-color;
						}
					}
				}
				.page-body-wrapper {
					background: $transparent-color;
					.page-body {
						background: $transparent-color;
					}
				}
			}
			&.material-icon {
				.page-body-wrapper {
					.page-title {
						background-color: transparent;
						box-shadow: none;
						border: none;
					}
				}
			}
			.page-header {
				.header-wrapper {
					.search-full {
						background-color: $dark-card-background;
						input {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		@media only screen and (min-width: 768px) {
			.page-wrapper {
				&.material-icon {
					.page-header {
						.header-wrapper {
							.nav-right {
								ul {
									li {
										.profile-dropdown {
											li {
												svg {
													stroke: $white;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		::-webkit-calendar-picker-indicator {
			filter: invert(1);
		}
		.kanban-board-header {
			background-color: $dark-body-background;
			border-bottom: 1px solid $dark-card-background;
		}
		.kanban-board .kanban-drag {
			background: $dark-body-background;
		}
		.kanban-container {
			.kanban-item {
				.kanban-box {
					border: 1px solid rgba($primary-color, 0.15);
					background-color: $dark-body-background;
				}
			}
		}
		.project-box {
			border: 1px solid rgba($primary-color, 0.15);
			background-color: $dark-body-background;
		}
		.file-content {
			.form-inline {
				border: 1px solid $dark-body-background;
			}
			.files {
				.file-box {
					border: 1px solid rgba($primary-color, 0.15);
					background-color: $dark-body-background;
					.file-top {
						background-color: $dark-card-background;
						border: 1px solid rgba($primary-color, 0.15);
					}
				}
			}
			.folder .folder-box {
				border: 1px solid rgba($primary-color, 0.15);
				background-color: $dark-body-background;
			}
		}
		.file-sidebar {
			.pricing-plan {
				border: 1px solid rgba($primary-color, 0.15) !important;
			}
			.btn-light {
				color: $dark-all-font-color;
				background-color: $dark-body-background !important;
				border: 1px solid rgba($primary-color, 0.15) !important;
			}
		}
		#lnb {
			border-right: 1px solid $dark-card-border;
		}
		.lnb-new-schedule,
		.lnb-calendars>div {
			border-bottom: 1px solid $dark-card-border;
		}
		#menu .sidebar-list .btn-default {
			color: rgba($dark-all-font-color, 0.2);
		}
		.tui-full-calendar-timegrid-hour {
			background-color: $dark-card-background;
			color: $white !important;
		}
		.tui-full-calendar-timegrid-gridline {
			border-bottom: 1px solid $dark-card-border !important;
		}
		.tui-full-calendar-time-date,
		.tui-full-calendar-weekday-grid-line,
		.tui-full-calendar-left,
		.tui-full-calendar-timegrid-timezone {
			border-right-color: $dark-card-border !important;
		}
		.tui-full-calendar-popup {
			color: $theme-body-font-color;
		}
		#menu {
			.btn-default {
				color: $dark-all-font-color;
				&:hover {
					color: $theme-body-font-color;
				}
			}
			.dropdown-menu {
				color: $dark-all-font-color;
			}
		}
		.tui-full-calendar-dayname-container,
		.tui-full-calendar-splitter {
			border-top-color: $dark-card-border !important;
			border-bottom-color: $dark-card-border !important;
		}
		span.tui-full-calendar-dayname-date-area {
			color: $white !important;
		}
		.tui-full-calendar-layout {
			background-color: $dark-card-background !important;
		}
		// index page css end	
		.blockquote {
			border-left: 4px solid $dark-card-border;
		}
		.figure {
			&.text-end {
				blockquote {
					border-right-color: $dark-card-border;
				}
			}
		}
		.contacts-tabs {
			.nav-pills {
				border-right: 1px solid $dark-card-border;
				.nav-link {
					+.nav-link {
						border-top: 1px solid $dark-card-border;
					}
				}
			}
		}
		.list-persons {
			.profile-mail {
				.d-flex {
					.flex-grow-1 {
						ul {
							li {
								+li {
									border-left: 2px solid $dark-card-border !important;
								}
							}
						}
					}
				}
			}
		}
		.product-wrapper {
			&.sidebaron {
				.product-sidebar {
					.filter-section {
						.card {
							.left-filter {
								background-color: $dark-card-background;
							}
						}
					}
				}
			}
		}
		.apexcharts-gridline {
			stroke: $dark-border-color;
		}
		.apexcharts-tooltip-title,
		.apexcharts-tooltip-series-group,
		.apexcharts-tooltip.light,
		.apexcharts-menu-item {
			color: $black;
		}
		// peity chart css
		.small-donut,
		.donut-peity2,
		.pie-peity {
			~svg,
			svg {
				path {
					&:nth-child(even) {
						fill: $dark-body-background;
					}
				}
			}
		}
		// index page css start		
		.notification {
			.card {
				.d-flex {
					.w-100 {
						p {
							span {
								color: $dark-all-font-color;
							}
						}
						span {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.datepicker {
			background-color: $dark-card-inbox;
			color: $dark-all-font-color;
			.datepicker--cell.-current- {
				color: var(--theme-default);
			}
			.datepicker--cell.-current-.-focus- {
				color: $white;
			}
			.datepicker--cell-day.-other-month-,
			.datepicker--cell-year.-other-decade- {
				color: rgba($white, 0.4);
			}
			.datepicker--nav {
				border-bottom: 1px solid $dark-card-border;
			}
			.datepicker--pointer {
				background: $dark-card-inbox;
				border-top-color: $dark-card-inbox;
				border-right-color: $dark-card-inbox;
			}
		}
		// dashbaord-3 css start
		.welcome-card {
			h4 {
				color: $dark-card-background;
			}
			h2 {
				color: $dark-body-background;
			}
		}
		// dashbaord-3 css end
		.page-header {
			.header-wrapper {
				.nav-right {
					.profile-dropdown {
						li {
							&:last-child {
								border-top: 1px solid $dark-card-border !important;
							}
						}
					}
				}
			}
		}
		$alert-name: primary,
		secondary,
		success,
		danger,
		warning,
		info,
		light,
		dark;
		$alert-color : $primary-color,
		$secondary-color,
		$success-color,
		$danger-color,
		$warning-color,
		$info-color,
		$light-color,
		$dark-color;
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var} {
				background-color: rgba(nth($alert-color, $i), 0.8) !important;
				border-color: rgba(nth($alert-color, $i), 0.9) !important;
				color: $white;
				.progress {
					height: 5px;
					background-color: darken(nth($alert-color, $i), 1%);
					border-radius: 0;
				}
				.progress-bar {
					background-color: lighten(nth($alert-color, $i), 50%);
				}
			}
			.alert-#{$var}.inverse {
				background-color: $transparent-color !important;
				.btn-close {
					filter: brightness(1) invert(1);
				}
			}
			.alert-#{$var}.outline,
			.alert-#{$var}.outline-2x {
				.btn-close {
					filter: brightness(1) invert(1);
				}
			}
			.dismiss-text {
				.alert-dismissible {
					.btn-close {
						filter: unset;
						.bg-light {
							color: $theme-body-font-color !important;
						}
					}
				}
			}
		}
		.options {
			>div {
				border: 1px solid $dark-card-border;
			}
		}
		.was-validated {
			.custom-control-input {
				&:invalid {
					~.custom-control-label {
						&::before {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}
		.pricing-simple {
			box-shadow: 1px 1px 2px 1px $dark-body-background !important;
		}
		.pricing-block {
			box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
		}
		.search-page {
			ul {
				&.search-info {
					li {
						+li {
							border-left: 1px solid $dark-body-background;
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				h6 {
					border-bottom: 1px solid $dark-border-color;
				}
			}
		}
		.job-search {
			.job-description {
				.theme-form {
					.row {
						div[class^="col-"] {
							.input-group {
								.datepicker-here {
									border: 1px solid $dark-body-background;
								}
							}
						}
					}
				}
			}
		}
		.calendar-wrap {
			.fc-unthemed {
				td,
				th {
					border-color: $dark-body-background;
				}
			}
		}
		.mega-inline,
		.mega-horizontal,
		.mega-vertical {
			--bs-body-color: rgba(255, 255, 255, 0.6);
			.card {
				box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
			}
			&.plain-style,
			&.border-style,
			&.offer-style {
				.card {
					box-shadow: none !important;
				}
			}
			&.border-style {
				.card {
					border: 1px solid $dark-card-border !important;
				}
			}
			&.offer-style {
				.card {
					border: 1px dashed $dark-card-border !important;
				}
			}
		}
		.setting-dot {
			.setting-bg {
				background-color: var(--theme-secondary);
			}
		}
		.bookmark.pull-right {
			border: none;
		}
		// popover css start
		.popover {
			&.bs-popover-bottom {
				.popover-arrow {
					&:after {
						border-bottom-color: $dark-body-background;
						border-right-color: $transparent-color;
					}
				}
			}
			&.bs-popover-top {
				.popover-arrow {
					&:after {
						border-right-color: $transparent-color;
						border-top-color: $dark-body-background;
					}
				}
			}
			&.bs-popover-start {
				.popover-arrow {
					&:after {
						border-left-color: $dark-body-background;
					}
				}
			}
			&.bs-popover-auto[data-popper-placement^=top] {
				.popover-arrow {
					&::after {
						border-top-color: $dark-body-background;
					}
				}
			}
			&.bs-popover-auto[data-popper-placement^=bottom] {
				.popover-arrow {
					&::after {
						border-bottom-color: $dark-body-background;
					}
				}
			}
			&.bs-popover-auto[data-popper-placement^=left] {
				.popover-arrow {
					&::after {
						border-left-color: $dark-body-background;
					}
				}
			}
			&.bs-popover-auto[data-popper-placement^=right] {
				.popover-arrow {
					&::after {
						border-right-color: $dark-body-background;
					}
				}
			}
			background-color: $dark-body-background;
			.popover-header {
				background-color: $dark-card-background;
			}
			.popover-body {
				color: rgba(255, 255, 255, 0.6);
			}
			code {
				background-color: unset;
			}
		}
		// popover css end
		// apex chart css start
		.apex-chart-container {
			.apexcharts-legend {
				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}
			}
		}
		.apexcharts-canvas {
			svg {
				.apexcharts-title-text {
					fill: $white;
				}
				.apexcharts-subtitle-text {
					fill: $white;
				}
				.apexcharts-yaxis {
					.apexcharts-yaxis-texts-g {
						.apexcharts-yaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-xaxis {
					.apexcharts-xaxis-texts-g {
						.apexcharts-xaxis-label {
							fill: $white;
						}
					}
				}
				.apexcharts-legend-series {
					span {
						color: $dark-all-font-color !important;
					}
				}
				.apexcharts-datalabels-group {
					.apexcharts-datalabel-label {
						fill: $white;
					}
					.apexcharts-datalabel-value {
						fill: $white;
					}
				}
			}
		}
		// apex chart css end
		.Typeahead-menu {
			background-color: $dark-body-background;
		}
		.ecommerce-widget {
			border: 1px solid $dark-border-color;
		}
		.bookmark {
			ul {
				li {
					.search-form {
						.form-control-search {
							input {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
							&:before {
								background: $dark-border-color;
							}
						}
					}
				}
			}
		}
		// cart css start
		.cart {
			.qty-box {
				.input-group {
					.btn {
						background-color: #2e3648 !important;
						border-color: $dark-card-border !important;
					}
				}
			}
		}
		// cart css end
		// checkout css start
		.checkout {
			.checkout-details {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				padding: 40px;
				.animate-chk {
					.radio_animated {
						&:after {
							border: 2px solid $dark-card-background;
						}
					}
				}
			}
		}
		// checkout css end
		.order-box {
			.title-box {
				color: #bfc2c6;
				border-bottom: 1px solid $dark-border-color;
			}
			.qty {
				li {
					color: #bfc2c6;
					span {
						color: #bfc2c6;
					}
				}
				border-bottom: 1px solid $dark-border-color;
			}
			.sub-total {
				li {
					color: #bfc2c6;
				}
			}
			.total {
				li {
					color: #bfc2c6;
				}
			}
		}
		.shopping-checkout-option {
			.checkbox_animated {
				&:after {
					border: 2px solid $dark-card-background;
				}
			}
		}
		.animate-chk {
			.radio_animated {
				&:after {
					border: 2px solid $dark-border-color;
				}
			}
		}
		.custom-scrollbar::-webkit-scrollbar-thumb {
			background-color: rgba(41, 50, 64, 0.52);
		}
		.product-box {
			.product-img {
				.product-hover {
					ul {
						li {
							.btn {
								color: $dark-small-font-color;
							}
							&:hover {
								.btn {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
		.radio_animated,
		.checkbox_animated {
			&:after {
				background: $dark-body-background;
				border: 2px solid $dark-body-background;
			}
		}
		.slider-product {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
		}
		.square-product-setting {
			.icon-grid {
				background-color: $dark-card-background;
				svg {
					color: $dark-all-font-color;
				}
			}
		}
		.active-order-table,
		.market-table {
			table {
				thead {
					tr {
						th {
							border-top: none !important;
						}
					}
				}
				tbody {
					tr {
						td {
							border-bottom: 1px solid $dark-border-color;
						}
						&:last-child {
							td {
								border-bottom: none;
							}
						}
					}
				}
			}
		}
		.pricing-card-design-2 {
			.pricing-block {
				.pricing-inner {
					ul {
						li {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
			}
		}
		.flot-chart-container {
			.legend {
				.table {
					tbody {
						background-color: $dark-card-background;
						color: $white;
						.legendLabel {
							padding-left: 5px;
						}
					}
				}
			}
		}
		.google-visualization-tooltip {
			text {
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}
		.nav-dark,
		.nav-pills.nav-dark {
			.nav-link {
				color: $white;
			}
		}
		.loader-wrapper {
			background-color: $dark-body-background;
			.loader {
				background-color: $dark-body-background !important;
			}
		}
		.page-wrapper {
			.sidebar-main-title {
				h6 {
					color: $dark-all-font-color;
				}
			}
			/* Main Header start */
			.page-header {
				.translate_wrapper {
					&.active {
						.more_lang {
							background-color: $dark-body-background;
						}
						.lang {
							background-color: $dark-card-background;
						}
					}
				}
				form {
					background-color: $dark-body-background;
				}
				.header-wrapper {
					li {
						i {
							color: $light-color;
						}
					}
					.nav-right {
						>ul {
							>li {
								svg {
									stroke: $white;
								}
							}
						}
						&.right-header {
							>ul {
								>li {
									.profile-media {
										.flex-grow-1 {
											p {
												color: $dark-all-font-color !important;
											}
										}
									}
								}
							}
						}
						.nav-menus {
							li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 14px $dark-body-background;
									li {
										border-color: $dark-card-background;
									}
									p {
										color: $dark-all-font-color;
									}
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										span {
											color: $dark-small-font-color;
										}
									}
									.bg-light {
										background-color: #282e38 !important;
										color: $dark-all-font-color !important;
									}
								}
							}
						}
						.profile-dropdown {
							li {
								a {
									color: $dark-all-font-color;
									svg {
										path,
										line,
										circle {
											color: $dark-all-font-color !important;
										}
									}
									&:hover {
										color: var(--theme-default);
										svg {
											line,
											path,
											circle {
												color: var(--theme-default) !important;
											}
										}
									}
								}
							}
						}
						>ul {
							>li {
								.d-flex {
									.flex-grow-1 {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
							}
						}
						.bookmark-flip {
							.bookmark-dropdown {
								.bookmark-content {
									.bookmark-icon {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
				}
				.header-logo-wrapper {
					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}
							.image-light {
								display: block;
							}
						}
					}
				}
			}
			/* Main Header ends */
			.page-body-wrapper {
				/* body start*/
				.page-body {
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										p {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
					}
					.header-small {
						color: $dark-small-font-color;
					}
					.statistics {
						p {
							color: $dark-small-font-color;
						}
					}
					.feather-main,
					.professor-table {
						.flex-grow-1,
						.professor-block {
							p {
								color: $dark-small-font-color;
							}
						}
					}
					.logs-element {
						span {
							+span {
								color: $dark-small-font-color
							}
						}
					}
					.progress-block {
						.progress-title {
							span {
								+span {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.ecommerce-icons {
						div {
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.new-users,
					.recent-notification {
						.d-flex {
							.flex-grow-1 {
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.topper-lists {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
										border-bottom: 1px solid $dark-card-border;
									}
									&:last-child {
										td {
											border-bottom: none;
										}
									}
								}
							}
						}
					}
					.upcoming-event {
						.upcoming-innner {
							border-bottom: 1px solid $dark-border-color;
							&:last-child {
								border-bottom: none;
							}
						}
					}
					.professor-table {
						table {
							tbody {
								tr {
									td {
										border-top: none !important;
									}
								}
							}
						}
					}
					.activity {
						.d-flex {
							.gradient-round {
								&.gradient-line-1,
								&.small-line,
								&.medium-line {
									&:after {
										background-color: $dark-border-color;
									}
								}
							}
							.flex-grow-1 {
								h6 {
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
					}
					.card.custom-card {
						.card-header {
							border-bottom: none !important;
						}
					}
					.card-header {
						.card-header-right-icon {
							.dropdown {
								.dropdown-menu {
									.dropdown-item {
										background-color: $bg-dark-color;
										color: $dark-all-font-color;
										+.dropdown-item {
											border-color: $light-all-font-color;
										}
									}
								}
							}
						}
					}
					.icon-dropdown {
						.dropdown-menu {
							.dropdown-item {
								background-color: $bg-dark-color;
								color: $dark-all-font-color;
								+.dropdown-item {
									border-color: $light-all-font-color;
								}
							}
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.8;
				}
				.page-title {
					.row {
						h3 {
							small {
								color: $dark-small-font-color;
							}
						}
					}
					.breadcrumb {
						li {
							color: $dark-all-font-color;
						}
						.breadcrumb-item {
							a {
								svg {
									stroke: $dark-all-font-color;
								}
							}
						}
					}
				}
				.page-body {
					background-color: $dark-body-background;
					.default-according {
						.card:not(.email-body) {
							border: none;
						}
					}
					.card:not(.email-body) {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
						border: 1px solid $dark-card-border;
						&.widget-1 {
							background-color: #1b1d24;
							background-blend-mode: overlay;
							.widget-round {
								.bg-round {
									box-shadow: 1px 2px 21px -2px rgba(14, 14, 16, 0.83);
									.half-circle {
										background: rgba(49, 53, 66, 0);
									}
								}
							}
						}
						&.social-profile {
							background-blend-mode: overlay;
							background-color: $dark-body-background;
						}
						.table {
							&.table-light {
								tr {
									th,
									td {
										color: $theme-body-font-color;
									}
								}
							}
						}
						table {
							thead,
							tbody {
								.border-bottom-light {
									th,
									td {
										border-bottom: 1px solid $dark-card-border !important;
									}
								}
							}
						}
						// cypto dashboard css start
						&.balance-box {
							background-blend-mode: overlay;
							background-color: #16171c;
						}
						// cypto dashboard css end
						.chart-block {
							#bar-chart2 {
								svg {
									>rect {
										fill: $dark-card-background;
									}
									>g {
										text {
											fill: $dark-all-font-color;
										}
									}
								}
							}
							.word-tree {
								svg {
									>g {
										>rect {
											fill: $dark-card-background;
										}
										>text {
											fill: $dark-all-font-color;
										}
									}
								}
							}
						}
						.card-header {
							background-color: $transparent-color;
							border-bottom: 1px solid $dark-card-border;
							>span {
								color: $dark-all-font-color;
							}
							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								background-color: unset;
								i {
									color: $dark-all-font-color;
									&.fa-cog {
										color: var(--theme-default);
									}
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline,
						.alert-light.outline-2x,
						.alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-card-border;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						thead,
						tbody,
						tfoot,
						tr,
						td,
						th {
							border-color: $dark-card-border;
						}
						.table {
							th,
							td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 1px solid $dark-card-border;
								}
								.border-bottom-primary {
									th {
										border-bottom: 1px solid var(--theme-default);
									}
								}
							}
							.table-primary,
							.table-secondary,
							.table-success,
							.table-info,
							.table-warning,
							.table-light,
							.table-danger {
								th,
								td {
									color: $dark-card-background;
								}
							}
							.bg-primary,
							.bg-secondary,
							.bg-success,
							.bg-info,
							.bg-warning,
							.bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							tbody {
								.border-bottom-primary {
									th,
									td {
										border-bottom: 1px solid var(--theme-default);
									}
								}
							}
						}
						.table[class*='bg-'] {
							th,
							td {
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										--bs-table-accent-bg: rgba(0, 0, 0, 0.05);
										&:hover {
											th,
											td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-double,
						.table-dotted,
						.table-dashed {
							border-left-color: $dark-card-border;
							border-right-color: $dark-card-border;
						}
						.table-bordered {
							border-color: $dark-card-border !important;
							td,
							th {
								border-color: $dark-card-border !important;
							}
						}
						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr,
							th,
							td {
								border-right: 1px solid $dark-border-color;
							}
						}
						.table-styling {
							thead,
							tbody {
								th,
								td {
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-card-border;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+.switch-state {
										background-color: var(--theme-default);
									}
								}
							}
						}
						.bg-white {
							background-color: $white !important;
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.ct-grid {
							stroke: $dark-border-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid var(--sidebar-border-color);
							opacity: inherit;
						}
						.text-muted {
							color: $dark-all-font-color !important;
						}
						.btn-outline-light,
						.btn-outline-dark,
						.btn-outline-light-2x {
							color: $white !important;
							border: 1px solid $dark-card-border;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#gantt_chart {
								svg {
									g {
										rect {
											&:first-child {
												fill: $dark-body-background;
											}
										}
									}
								}
								rect {
									&:nth-child(6) {
										fill: $dark-body-background;
									}
								}
							}
							&#line-chart,
							&#column-chart1,
							&#column-chart2 {
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								>rect {
									fill: $dark-card-background;
								}
								>g {
									>g {
										>g {
											text {
												fill: rgb(177, 177, 178);
											}
										}
									}
									>text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-card-border !important;
						}
						.chart-container {
							.live-products,
							.turnover,
							.monthly,
							.uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart,
							#website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-card-border;
						}
						.dataTables_wrapper {
							button {
								color: $black;
							}
							&.no-footer {
								.dataTables_scrollBody {
									border-bottom: 1px solid $dark-body-background;
								}
							}
							.btn-danger,
							.btn-success,
							.btn-primary {
								color: $white;
							}
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate {
								border: 1px solid $dark-border-color;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											>.sorting_1 {
												background-color: $dark-datatable-sorting;
											}
										}
									}
									tr.odd {
										background-color: $dark-datatable-odd;
										>.sorting_1 {
											background-color: $dark-datatable-sorting;
										}
									}
									tr.even {
										>.sorting_1 {
											background-color: $dark-datatable-sorting-even;
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-border-color;
								thead {
									th,
									td {
										border-bottom: 2px solid $dark-border-color;
									}
								}
								input,
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox,
									th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											--bs-table-accent-bg: #1d1e26;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
									&.current,
									&:active {
										border-color: var(--theme-default);
									}
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover,
									:active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border,
							table.dataTable.display {
								tbody {
									th,
									td {
										border-top: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.display,
							table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											>.sorting_1 {
												background-color: $dark-even-hover-sorting;
											}
										}
									}
								}
							}
							table.dataTable.hover,
							table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: $dark-datatable-sorting;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th,
								td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.order-column,
							table.dataTable.display {
								tbody {
									tr {
										>.sorting_1,
										>.sorting_2,
										>.sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.page-link {
							border: 1px solid $dark-card-border;
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							color: $dark-all-font-color;
							background-color: $dark-card-background;
						}
						.page-item {
							&:hover {
								.page-link {
									background-color: $dark-body-background;
								}
							}
						}
						.page-item.active {
							.page-link {
								background-color: $dark-body-background;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {
											span,
											p {
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6 {
								color: $dark-small-font-color;
							}
						}
						.border-tab.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								.nav-link {
									color: $dark-all-font-color;
								}
							}
							.nav-link {
								&.active,
								&:focus,
								&:hover {
									color: var(--theme-default);
								}
							}
						}
						.br-theme-bars-1to10,
						.br-theme-bars-movie,
						.br-theme-bars-pill,
						.br-theme-bars-reversed,
						.br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: $dark-border-color;
									&.br-active,
									&.br-selected {
										background-color: var(--theme-default);
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected {
										border: 2px solid var(--theme-default);
										color: var(--theme-default);
									}
								}
							}
						}
						.br-theme-fontawesome-stars,
						.br-theme-fontawesome-stars-o {
							.br-widget {
								a {
									&.br-selected,
									&.br-active {
										&:after {
											color: var(--theme-default);
										}
									}
								}
							}
						}
						.scroll-demo {
							border: 1px solid $dark-card-border;
						}
						.search-form {
							input {
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								background-color: $dark-body-background;
							}
							.form-group {
								&:before {
									background: $dark-card-background;
								}
							}
						}
						.cd-timeline-content {
							background-color: $dark-border-color;
						}
						.cd-timeline-block {
							&:nth-child(odd) {
								.cd-timeline-content {
									&::before {
										border-left-color: $dark-border-color;
									}
								}
							}
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: $dark-border-color;
									}
								}
							}
						}
						.breadcrumb {
							&.bg-white {
								background-color: $dark-card-background !important;
							}
						}
						#donut-color-chart-morris-daily,
						#donut-color-chart-morris,
						#browser-uses-chart,
						#website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							>div {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}
					.jsgrid-header-row,
					.jsgrid-filter-row {
						>.jsgrid-header-cell,
						>.jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;
							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;
								&:focus {
									outline: none;
								}
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						>.jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						>.jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell,
					.jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}
					.jsgrid-selected-row {
						>.jsgrid-cell {
							background-color: $dark-datatable-sorting;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}
					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.card {
						.sub-title {
							color: $dark-all-font-color;
						}
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: var(--theme-default);
								}
							}
						}
						.separator {
							border-bottom: 1px solid $dark-card-border;
						}
					}
					.default-according {
						li {
							.text-muted {
								color: $dark-all-font-color !important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: var(--theme-default) !important;
								}
							}
						}
					}
					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: $dark-all-font-color;
								}
								&:hover {
									color: var(--theme-default);
									a {
										color: var(--theme-default);
									}
								}
							}
						}
					}
					.navs-dropdown {
						.onhover-show-div {
							background-color: $dark-body-background;
							box-shadow: 0 0 14px 0 $dark-body-background;
							.navs-icon {
								li {
									p {
										color: $white;
									}
									a {
										svg {
											path,
											line,
											polyline,
											polygon,
											rect {
												color: $dark-all-font-color !important;
											}
										}
										&:hover {
											svg {
												path,
												line,
												polyline,
												polygon,
												rect {
													color: var(--theme-default) !important;
												}
											}
										}
									}
								}
							}
						}
					}
					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid $dark-body-background;
								}
							}
						}
					}
					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
							}
							.card-body {
								border: 1px solid $dark-card-border;
								border-top: none;
							}
						}
					}
					.border {
						border: 1px solid $dark-card-border !important;
					}
					.border-1 {
						border: 1px solid $dark-body-background !important;
					}
					.border-2 {
						border: 2px solid $dark-body-background !important;
					}
					.grid-showcase {
						span {
							&.border-2 {
								border: 2px solid $dark-card-border !important;
							}
						}
					}
					.border-3 {
						border: 3px solid $dark-body-background !important;
					}
					.border-4 {
						border: 4px solid $dark-body-background !important;
					}
					.border-5 {
						border: 5px solid $dark-body-background !important;
					}
					.border-6 {
						border: 6px solid $dark-body-background !important;
					}
					.border-7 {
						border: 7px solid $dark-body-background !important;
					}
					.border-8 {
						border: 8px solid $dark-body-background !important;
					}
					.border-9 {
						border: 9px solid $dark-body-background !important;
					}
					.border-10 {
						border: 10px solid $dark-body-background !important;
					}
					$border-gradient: primary,
					secondary,
					success,
					danger,
					warning,
					info,
					light,
					dark;
					$helper-border-color : $primary-color,
					$secondary-color,
					$success-color,
					$danger-color,
					$warning-color,
					$info-color,
					$light-color,
					$dark-color;
					@each $var in $border-gradient {
						$i: index($border-gradient, $var);
						.b-#{$var} {
							border: 1px solid nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}
						.border-t-#{$var} {
							border: none !important;
							border-top: 1px solid nth($helper-border-color, $i) !important;
						}
						.b-t-#{$var} {
							border: 1px solid nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}
						.border-b-#{$var} {
							border: none !important;
							border-bottom: 1px solid nth($helper-border-color, $i) !important;
						}
						.b-b-#{$var} {
							border: 1px solid nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}
						.border-l-#{$var} {
							border: none !important;
							border-left: 1px solid nth($helper-border-color, $i) !important;
						}
						.b-l-#{$var} {
							border: 1px solid nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}
						.border-r-#{$var} {
							border: none !important;
							border-right: 1px solid nth($helper-border-color, $i) !important;
						}
						.b-r-#{$var} {
							border: 1px solid nth($helper-border-color, $i) !important;
							background-color: $dark-body-background;
						}
						.border-#{$var} {
							border: 1px solid nth($helper-border-color, $i) !important;
						}
					}
				}
				.footer {
					p {
						color: $dark-all-font-color;
					}
					border-top: 1px solid $dark-body-background;
					background-color: $dark-card-background;
				}
				.custom-select,
				.custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}
				.footer-fix {
					background-color: $dark-card-background;
					border-top: 1px solid $dark-border-color;
				}
			}
			.note {
				textarea {
					color: $dark-all-font-color;
				}
			}
			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;
				h2 {
					background-color: $dark-card-background;
				}
			}
			pre {
				background-color: $dark-border-color;
				color: $dark-all-font-color;
			}
			.scorlled {
				background-color: $dark-body-background;
			}
			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-border-color;
			}
			.input-group-text {
				color: rgba(255, 255, 255, 0.6);
				i {
					color: rgba(255, 255, 255, 0.6);
				}
			}
			.input-group-solid {
				.input-group-text,
				.form-control {
					background: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
			.semilight-bg-color,
			.header-bg-color {
				background-color: $white;
			}
			.list-group-item {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.3);
				color: $dark-all-font-color;
				+.list-group-item {
					border-top-width: 0;
				}
				&.active {
					background-color: var(--theme-default) !important;
					border-color: var(--theme-default);
					color: $white;
				}
				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}
			.list-group-item-action {
				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}
			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}
			.dark-list {
				.list-group {
					.border-left-primary {
						border-left: 4px solid var(--theme-default);
					}
					.border-left-secondary {
						border-left: 4px solid var(--theme-secondary);
					}
					.border-left-warning {
						border-left: 4px solid $warning-color;
					}
					.border-left-success {
						border-left: 4px solid $success-color;
					}
					.border-left-info {
						border-left: 4px solid $info-color;
					}
				}
			}
			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}
			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}
			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}
			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}
			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}
			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}
			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}
			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}
			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);
				.authentication-box {
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
								}
								label {
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									color: $dark-all-font-color;
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
					h3,
					h4,
					h6 {
						color: $white;
					}
				}
			}
			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					h4,
					h3 {
						color: $white;
					}
					h6 {
						color: $dark-small-font-color;
					}
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input[type=text],
								input[type=password] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
				}
			}
			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-border-color;
						}
						&:nth-child(3) {
							i {
								color: $dark-border-color;
							}
						}
					}
				}
			}
			.modal-content {
				background-color: $dark-card-background;
				.modal-header {
					border-bottom: 1px solid $dark-border-color;
					.close {
						color: $dark-small-font-color;
					}
				}
				.modal-footer {
					border-top: 1px solid $dark-border-color;
				}
			}
			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.9);
				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}
					.countdown {
						border-top: 1px solid $dark-border-color;
						border-bottom: 1px solid $dark-border-color;
						.title {
							color: $dark-all-font-color;
						}
					}
					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
				}
			}
			.theme-form {
				.login-divider {
					border-top: 1px solid $dark-border-color;
					&:before {
						background: $dark-border-color;
						color: $dark-all-font-color;
					}
				}
			}
			.authentication-main {
				background-color: $dark-border-color;
				.auth-innerright {
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}
						.reset-password-link {
							color: $dark-small-font-color;
						}
						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}
								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-border-color;
								}
							}
							.opt-box {
								background-color: $dark-border-color;
							}
						}
					}
					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}
						h6 {
							color: $dark-small-font-color;
						}
						h3 {
							color: $dark-all-font-color;
						}
						.card {
							background-color: $dark-card-background;
							.theme-form {
								.form-group {
									input[type=text],
									input[type=password] {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
										color: $dark-all-font-color;
									}
								}
								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-border-color;
										}
									}
								}
							}
						}
					}
				}
			}
			.vertical-menu-main {
				background-color: $dark-card-background;
			}
			.mega-menu {
				.title {
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}
				.list-unstyled {
					div {
						a {
							&:hover {
								color: var(--theme-default);
							}
						}
					}
				}
			}
			.default-according {
				.card {
					background-color: $dark-card-background;
					.btn-link {
						background-color: $dark-card-background;
						border: 1px solid $dark-card-border;
						border-bottom: none;
						color: $white;
					}
					.text-muted {
						color: $dark-small-font-color !important;
					}
				}
				.bg-primary {
					.btn-link {
						background-color: var(--theme-default);
						border: 1px solid var(--theme-default);
					}
				}
				.bg-secondary {
					.btn-link {
						background-color: var(--theme-secondary);
						border: 1px solid var(--theme-secondary);
					}
				}
			}
			.collapse {
				.card-body {
					background-color: $dark-card-background;
				}
			}
			@media screen and (max-width: 1660px) {
				.caller-img {
					img {
						opacity: 0.7;
					}
				}
			}
			@media only screen and (max-width: 1199px) {
				.mobile-title {
					&.d-none {
						border-bottom: 1px solid $dark-card-border;
					}
				}
				.chat-menu {
					border-top: 1px solid $dark-border-color;
					background-color: $dark-card-background;
				}
				.error-wrapper {
					.maintenance-icons {
						li {
							i {
								color: $dark-color;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 575.98px) {
				.user-profile {
					.hovercard {
						.info {
							.user-designation {
								border-top: 1px solid $dark-border-color;
								border-bottom: 1px solid $dark-border-color;
							}
						}
					}
				}
				.page-wrapper {
					&.compact-wrapper,
					&.compact-sidebar {
						.page-header {
							.header-wrapper {
								.toggle-sidebar {
									border-right: 1px solid $dark-card-border;
								}
							}
						}
					}
				}
			}
		}
		.lg-backdrop {
			background-color: $dark-body-background;
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}
		.drag {
			background-color: $dark-card-background;
			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-border-color;
				border: 1px dotted $dark-border-color;
				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}
		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			p {
				color: $dark-small-font-color;
			}
			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			.radio,
			.checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
				}
			}
		}
		.select2-container--default {
			.select2-selection--multiple,
			.select2-selection--single {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color !important;
			}
			.select2-search--inline {
				.select2-search__field {
					color: $dark-all-font-color;
				}
			}
			.select2-selection--single {
				.select2-selection__rendered {
					color: $dark-all-font-color;
				}
			}
			.select2-search--dropdown {
				.select2-search__field {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
			.select2-results__option--highlighted[aria-selected] {
				background-color: $dark-card-background !important;
				color: $dark-all-font-color !important;
			}
		}
		.select2-dropdown {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: var(--theme-default) !important;
				color: var(--theme-default) !important;
			}
			.form-control-secondary {
				border-color: var(--theme-secondary) !important;
				color: var(--theme-secondary) !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $info-color !important;
				color: $info-color !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill {
				background-color: var(--theme-default) !important;
				color: $white !important;
			}
			.form-control-secondary-fill {
				background-color: var(--theme-secondary) !important;
				color: $white !important;
			}
			.form-control-success-fill {
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill {
				background-color: $info-color !important;
				color: $white !important;
			}
			.form-control-warning-fill {
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill {
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill {
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled {
			.select2-selection--single {
				background-color: $dark-border-color;
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color;
				}
				input[type=text],
				input[type=email],
				input[type=search],
				input[type=password],
				input[type=number],
				input[type=tel],
				input[type=date],
				input[type=datetime-local],
				input[type=time],
				input[type=datetime-local],
				input[type=month],
				input[type=week],
				input[type=url],
				input[type=file],
				select {
					border-color: $dark-border-color;
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}
				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
				textarea {
					border-color: $dark-border-color;
				}
			}
			.form-divider {
				border-top: 1px solid $dark-border-color;
				&::before {
					background: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.CodeMirror {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
			color: $dark-all-font-color;
			.CodeMirror-code {
				pre {
					background: $dark-card-background;
				}
			}
		}
		.swal-modal {
			background-color: $dark-card-background;
			.swal-title {
				color: $dark-all-font-color;
			}
			.swal-text {
				color: $dark-small-font-color;
			}
			.swal-content__input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}
		.nav-tabs {
			border-bottom: 1px solid $dark-card-border;
			.nav-link {
				color: $dark-all-font-color;
				&.active {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-card-border $dark-card-border $dark-card-background;
				}
				&:hover,
				&:focus {
					border-color: $dark-card-border $dark-card-border $dark-card-inbox;
				}
			}
			.nav-item.show {
				.nav-link {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
			}
		}
		.nav-primary {
			.nav-link {
				&.active {
					background-color: var(--theme-default);
				}
			}
			.nav-item.show {
				.nav-link {
					background-color: var(--theme-default);
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {
					&.active,
					&.focus,
					&.hover {
						border-color: $dark-card-background $dark-border-color $dark-border-color;
					}
				}
			}
		}
		.border-tab {
			.nav-link {
				&.active {
					background-color: transparent;
				}
			}
			.show {
				>.nav-link {
					background-color: transparent;
				}
			}
		}
		.border-tab.nav-left,
		.border-tab.nav-right {
			.nav-link {
				color: $dark-all-font-color;
				&.active {
					color: var(--theme-default);
				}
			}
			.show {
				>.nav-link {
					color: var(--theme-default);
				}
			}
		}
		.border-tab.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					border-left-color: var(--theme-secondary);
					color: var(--theme-secondary) !important;
				}
			}
			.show>.nav-link {
				border-left-color: var(--theme-secondary);
				color: var(--theme-secondary) !important;
			}
			.nav-item.show {
				color: var(--theme-secondary) !important;
				border-left-color: var(--theme-secondary);
			}
		}
		.border-tab.nav-left.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.show>.nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.border-tab.nav-right.nav-info {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}
			.show>.nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.border-tab.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: var(--theme-secondary) !important;
				}
			}
			.nav-item {
				&.show {
					color: var(--theme-secondary) !important;
				}
			}
		}
		.border-tab.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider {
			border-top: 1px solid $dark-border-color;
		}
		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-border-color;
			.icon-title {
				color: $dark-all-font-color;
			}
			span {
				color: $dark-small-font-color;
			}
			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}
		code {
			background-color: $dark-body-background;
			border-radius: 2px;
		}
		#cd-timeline {
			&::before {
				background: $dark-border-color;
			}
		}
		.timeliny {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
			&::before {
				background-color: $white;
			}
			.timeliny-dot {
				background-color: $dark-card-background;
				border: 1px solid $white;
				&::before {
					color: $dark-all-font-color;
				}
			}
			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: $dark-all-font-color;
							}
							&::after {
								background-color: $dark-border-color;
								color: $dark-all-font-color;
								border: none;
							}
						}
					}
					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;
								&::before {
									color: $dark-all-font-color;
								}
							}
						}
					}
					.timeliny-dot {
						&:hover {
							&::after {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		.todo {
			.todo-list-wrapper {
				#todo-list {
					li {
						.task-container {
							background: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							.task-label {
								color: $dark-all-font-color;
							}
							&:hover {
								h4 {
									color: $white;
								}
							}
							.task-action-btn {
								.action-box {
									&:hover:not(.active) {
										background: $dark-card-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
						&.completed {
							.task-container {
								.task-label {
									color: var(--theme-default);
								}
								.complete-btn {
									&:hover {
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper {
						textarea {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.user-profile {
			.hovercard {
				.info {
					.title {
						a {
							color: $dark-all-font-color;
						}
					}
				}
				.user-image {
					.avatar {
						img {
							border: 10px solid $dark-card-background;
						}
					}
					.icon-wrapper {
						background-color: $dark-card-background;
					}
				}
				.tabs-scoial {
					border-bottom: none !important;
				}
			}
			.follow {
				.follow-num {
					color: $dark-all-font-color;
				}
			}
			.profile-img-style {
				.user-name {
					color: $dark-all-font-color;
				}
				p {
					color: $dark-small-font-color;
				}
			}
		}
		.introjs-helperLayer {
			background-color: rgba(54, 64, 74, 0.9);
			border: rgba(255, 255, 255, 0.5);
		}
		.jstree-default {
			.jstree-node,
			.jstree-icon {
				color: #455869 !important;
			}
			.jstree-anchor {
				color: $dark-small-font-color;
			}
			.jstree-clicked {
				color: $white;
				background-color: $transparent-color;
			}
			.jstree-hovered {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
			}
		}
		span.twitter-typeahead {
			.tt-menu {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
			.tt-suggestion {
				color: $dark-all-font-color;
				background-color: $dark-body-background;
				border-top: 1px solid $dark-card-border;
				&:hover,
				&:focus {
					background-color: $dark-card-background;
				}
			}
		}
		.typography {
			small {
				color: $dark-all-font-color;
			}
		}
		.code-box-copy {
			pre {
				background-color: $dark-body-background;
				code {
					background-color: $dark-body-background;
				}
			}
			pre[class*="language-"] {
				border: 1px solid $dark-border-color;
			}
			.code-box-copy__btn {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				color: $white;
			}
			code[class*=language-],
			pre[class*=language-] {
				text-shadow: 0px 1px $black;
				::selection {
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}
		table.fixedHeader-floating {
			background-color: $dark-body-background;
		}
		.note {
			textarea {
				color: $dark-all-font-color;
			}
		}
		.dt-button-info {
			background-color: $dark-card-background;
			border: 1px solid $dark-border-color;
			h2 {
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-border-color;
			}
		}
		pre {
			background-color: $dark-body-background;
		}
		#keytable_wrapper,
		#column-selector_wrapper,
		#auto-fill_wrapper,
		#custom-button_wrapper,
		#class-button_wrapper,
		#keyboard-btn_wrapper,
		#multilevel-btn_wrapper,
		#pagelength-btn_wrapper,
		#basic-colreorder_wrapper,
		#state-saving_wrapper,
		#real-time_wrapper,
		#basic-fixed-header_wrapper,
		#fixed-header-footer_wrapper,
		#export-button_wrapper,
		#excel-cust-bolder_wrapper,
		#cust-json_wrapper,
		#basic-key-table_wrapper,
		#focus-cell_wrapper,
		#responsive_wrapper,
		#new-cons_wrapper,
		#show-hidden-row_wrapper,
		#basic-row-reorder_wrapper,
		#full-row_wrapper,
		#rest-column_wrapper {
			.dataTables_paginate {
				border: none;
			}
		}
		#example-style-8_wrapper {
			table.dataTable.stripe,
			table.dataTable.display {
				tbody {
					tr.odd {
						background-color: $dark-border-color;
					}
				}
			}
		}
		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.8);
			.sub-content {
				color: $dark-all-font-color;
			}
		}
		.b-light {
			border: 1px solid $dark-border-color !important;
		}
		.modal-content {
			background-color: $dark-card-background;
			.modal-header {
				border-bottom: 1px solid $dark-card-border;
				.close {
					color: $dark-all-font-color;
					font-weight: 400;
				}
			}
			.ui-front {
				.form-control {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-card-border;
				}
			}
		}
		.modal {
			.theme-close {
				color: $light-all-font-color;
			}
		}
		.token.atrule,
		.token.attr-value,
		.token.keyword {
			color: $info-color;
		}
		.token {
			&.boolean,
			&.constant,
			&.deleted,
			&.number,
			&.property,
			&.symbol,
			&.tag {
				color: $danger-color;
			}
		}
		.loader-box {
			&.card-loader {
				background-color: $dark-card-background;
			}
		}
		.my-gallery {
			&.gallery-with-description {
				img {
					border: 1px solid $dark-border-color !important;
					border-bottom: none !important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse {
				color: $dark-all-font-color;
			}
		}
		.alert-theme {
			span {
				+span {
					+span {
						border-left: 5px solid var(--theme-default);
						background-color: $dark-body-background;
						border-radius: 4px;
					}
				}
			}
			i {
				color: $white;
				padding: 20px;
				margin-right: 20px !important;
			}
		}
		.user-card {
			.user-deatils {
				h6 {
					color: $dark-small-font-color;
				}
			}
			.card-footer {
				>div {
					&:first-child {
						border-right: 1px solid $dark-border-color;
					}
				}
				.user-footer {
					h6 {
						color: $dark-small-font-color;
					}
					svg {
						path,
						rect {
							color: $dark-small-font-color;
						}
					}
				}
			}
		}
		.order-history {
			table {
				.qty-box {
					button {
						background-color: $dark-body-background !important;
						border: none !important;
					}
					.input-group {
						border-color: $dark-card-border;
					}
				}
				thead {
					tr {
						th {
							background-color: $dark-body-background;
						}
					}
				}
				tr {
					td,
					th {
						border-top: none !important;
					}
				}
			}
			.title-orders {
				background-color: $dark-body-background;
			}
		}
		.navigation-option {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}
					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.product-box {
			.product-details {
				h6 {
					color: $dark-all-font-color;
				}
				>a {
					color: $dark-all-font-color;
				}
				span {
					color: $dark-small-font-color;
				}
			}
			.product-img {
				.product-hover {
					ul {
						li {
							background-color: $dark-card-background;
							&:hover {
								background-color: $black;
								color: $white;
							}
						}
					}
				}
			}
		}
		.browse {
			.browse-articles {
				ul {
					li {
						a {
							color: $dark-all-font-color;
						}
						&:hover {
							background-color: $dark-body-background;
						}
					}
				}
			}
		}
		@each $btn-name,
		$btn-color in (primary, var(--theme-default)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.radio-#{$btn-name} {
				input[type="radio"] {
					&+label {
						&::before {
							border-color: $btn-color !important;
						}
						&::after {
							background-color: $btn-color;
						}
					}
					&:checked {
						&+label {
							&::before {
								border-color: $btn-color !important;
							}
							&::after {
								background-color: $btn-color;
							}
						}
					}
				}
			}
		}
		@each $btn-name,
		$btn-color in (primary, var(--theme-default)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.checkbox-#{$btn-name} {
				label {
					&::before {
						border-color: $btn-color !important;
					}
				}
			}
		}
		@each $btn-name,
		$btn-color in (primary, var(--theme-default)),
		(secondary, $secondary-color),
		(success, $success-color),
		(danger, $danger-color),
		(info, $info-color),
		(light, $light-color),
		(dark, $dark-color),
		(warning, $warning-color) {
			.checkbox-solid-#{$btn-name} {
				label {
					&:before {
						background-color: $btn-color !important;
						border-color: $btn-color !important;
						color: $white !important;
					}
				}
				input[type="checkbox"] {
					&:checked {
						&+label {
							&::before {
								background-color: $btn-color !important;
								border-color: $btn-color !important;
								color: $white !important;
							}
							&::after {
								color: $white !important;
							}
						}
					}
				}
			}
		}
		.bootstrap-datetimepicker-widget {
			ul {
				li {
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}
			}
			tr {
				th,
				td {
					&:hover {
						background-color: $dark-card-background;
					}
					span {
						&:hover {
							background-color: $dark-card-background;
						}
					}
					&.prev,
					&.next {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}
				&:first-child {
					th {
						&:hover {
							background-color: $dark-card-background;
						}
					}
				}
			}
		}
		#cal-basic {
			.fc-toolbar {
				.fc-left,
				.fc-right {
					.fc-next-button {
						.fc-icon-right-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
					.fc-prev-button {
						.fc-icon-left-single-arrow {
							&:after {
								color: $dark-small-font-color;
							}
						}
					}
				}
				.fc-left {
					.fc-button-group {
						.fc-month-button {
							color: $white !important;
						}
					}
				}
			}
		}
		.fc-button-group {
			.fc-basicWeek-button,
			.fc-basicDay-button,
			.fc-agendaWeek-button,
			.fc-agendaDay-button {
				color: $black !important;
			}
			.fc-month-button {
				color: $white !important;
			}
		}
		.btn-light {
			&:not([disabled]):not(.disabled).active.active {
				background-color: $dark-body-background !important;
			}
			&.disabled,
			&:disabled {
				color: rgba($theme-body-font-color, 0.5) !important;
			}
		}
		#cal-agenda-view {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-month-button,
						.btn-light {
							color: $black !important;
						}
						.fc-agendaWeek-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.basic-calendar,
		#cal-bg-events,
		#cal-event-colors {
			.fc-toolbar {
				.fc-right {
					.fc-button-group {
						.fc-basicWeek-button,
						.btn-light {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
							&:hover {
								color: $black !important;
							}
						}
					}
				}
			}
		}
		.categories {
			ul {
				li {
					a {
						color: $dark-small-font-color;
					}
					&:hover {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.socialprofile {
			.likes-profile {
				h5 {
					span {
						color: $dark-all-font-color;
					}
				}
			}
			span {
				color: $dark-small-font-color;
			}
		}
		.social-status {
			.d-flex {
				.flex-grow-1 {
					span {
						+span {
							color: $dark-small-font-color;
						}
					}
					p,
					.light-span {
						color: $dark-small-font-color;
					}
				}
			}
		}
		.filter-cards-view,
		.timeline-content {
			p {
				color: $dark-small-font-color;
			}
			.comment-number {
				i {
					color: $dark-small-font-color;
				}
			}
			.comments-box {
				.input-group {
					.form-control {
						border: none !important;
					}
					.input-group-append {
						background-color: $dark-body-background;
					}
				}
			}
		}
		.social-chat {
			.flex-grow-1 {
				border: 1px solid $dark-border-color;
				&:after {
					border-right: 7px solid $dark-card-background;
				}
			}
			span {
				span {
					color: $dark-small-font-color;
				}
			}
		}
		.details-about {
			.your-details {
				p {
					color: $dark-small-font-color;
				}
			}
		}
		.activity-log {
			.my-activity {
				p {
					color: $dark-small-font-color;
				}
			}
		}
		.bookmark {
			ul {
				li {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}
		//index page
		@media only screen and (max-width: 991.98px) {
			.page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
				border-right: 1px solid $dark-card-border;
			}
		}
		@media only screen and (max-width: 767.98px) {
			.page-wrapper {
				&.material-icon {
					.page-header {
						background-color: $dark-card-background;
					}
				}
			}
		}
		.apexcharts-tooltip.light {
			border-color: $dark-card-border;
			background-color: $dark-body-background;
			.apexcharts-tooltip-title {
				border-color: $dark-card-border;
			}
			.apexcharts-tooltip-text-label,
			.apexcharts-tooltip-text-value {
				color: $white;
			}
		}
		// Alert dark mode
		.dark-alert {
			.alert {
				p {
					color: $dark-body-background;
				}
			}
		}
		.dark-txt {
			p {
				strong {
					color: $dark-all-font-color !important;
				}
			}
		}
		.live-dark {
			.btn-close {
				filter: initial;
			}
		}
		.alert-light {
			color: $dark-body-background !important;
		}
		// dropdown dark mode
		.dark-input-type {
			.input-group {
				.input-group-text {
					background: transparent;
					border: unset;
				}
			}
		}
		.dark-dropdown {
			.dropdown-menu {
				li {
					.dropdown-item {
						color: $dark-all-font-color;
					}
					+li {
						.dropdown-item {
							border-top-color: $dark-card-border;
						}
					}
				}
			}
		}
		.rtl-dropdown {
			.dropdown-block {
				&.dark-form {
					background-color: $dark-body-background;
					li {
						h6 {
							color: $dark-all-font-color;
						}
						p {
							background-color: transparent;
							color: $light-all-font-color;
							border-top: 1px solid $dark-card-border !important;
						}
					}
				}
			}
		}
		.heading-dropdown {
			.dropdown-menu {
				>li {
					&:first-child {
						>a {
							background-color: $dark-even-hover-sorting;
							&:hover {
								background-color: unset;
							}
						}
					}
				}
			}
		}
		// select2
		.selected-box {
			color: $dark-all-font-color;
		}
		// Accordion dark mode
		.dark-accordion {
			.accordion-item {
				.accordion-collapse {
					.accordion-body {
						background-color: $dark-card-background;
						h6 {
							border: 0;
						}
					}
				}
				&:last-of-type {
					.accordion-button.collapsed {
						background-color: $dark-card-background;
					}
				}
				border: 1px solid $dark-card-border;
				background-color: $dark-card-border;
			}
			&.accordion-flush {
				.accordion-item {
					border: unset;
				}
			}
			.collapse-wrapper {
				color: $dark-body-background;
			}
			&.card {
				border-radius: unset;
			}
		}
		.accordions-content {
			li {
				color: $dark-all-font-color;
			}
		}
		// List dark mode
		.dark-list {
			.list-group {
				.list-group-item {
					+.list-group-item {
						border-top-width: 1px;
					}
					.form-check-input {
						border: 1px solid $dark-all-font-color;
						background-color: transparent;
					}
				}
			}
			.list-light-dark {
				background-color: $dark-body-background;
			}
		}
		// Typography dark mode
		.card-wrapper {
			.sub-title {
				border-bottom: unset !important;
			}
		}
		.dark-blockquote {
			.blockquote {
				p {
					color: $dark-all-font-color !important;
				}
			}
		}
		// blog
		.comment-box {
			.comment-social {
				li {
					color: $dark-small-font-color;
				}
			}
			img {
				border-color: $dark-card-border;
			}
		}
		// Buttons
		.buttons-box {
			button {
				color: $dark-card-background;
			}
		}
		.btn-radio {
			.btn-group {
				.btn {
					label {
						color: $dark-card-background;
					}
				}
			}
		}
		.btn-group {
			&.btn-option {
				.btn {
					label {
						color: $dark-card-background;
					}
				}
			}
		}
		// Modal dark mode
		.dark-sign-up {
			h3 {
				color: $dark-card-background;
			}
			p {
				color: $dark-card-background;
			}
			.modal-body {
				form {
					.form-label {
						color: $bg-dark-color;
					}
					.form-check {
						.form-check-label {
							color: $bg-dark-color;
						}
					}
				}
			}
		}
		.modal-dialog {
			.modal-content {
				.dark-modal {
					.large-modal-header {
						svg {
							color: $dark-all-font-color;
						}
						h6 {
							color: $dark-all-font-color;
						}
					}
					.large-modal-body {
						svg {
							color: $dark-all-font-color;
						}
						p {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
		.modal-toggle-wrapper {
			.dark-toggle-btn {
				color: $dark-all-font-color;
			}
		}
		.social-profile {
			background-attachment: fixed;
			.social-details {
				h5 {
					a {
						color: $dark-all-font-color;
					}
				}
			}
		}
		// helper-classes
		.dark-helper {
			background-color: rgba($dark-body-background, 0.4);
			h6,
			span {
				color: $dark-all-font-color;
			}
			.text-muted {
				color: $dark-card-background !important;
			}
		}
		.font-dark {
			color: $dark-all-font-color !important;
		}
		// treeview
		.treejs {
			.treejs-node__disabled {
				color: $light-all-font-color;
			}
			.treejs-switcher:before {
				border-top: 4px solid $dark-all-font-color
			}
		}
		// range-slider 
		.irs-min,
		.irs-max {
			color: $dark-small-font-color;
			background-color: $dark-card-inbox;
		}
		// Ribbons 
		.ribbon-wrapper,
		.ribbon-wrapper-bottom,
		.ribbon-vertical-left-wrapper,
		.ribbon-wrapper-right,
		.ribbon-vertical-right-wrapper {
			&.alert-light-light {
				background-color: $dark-card-inbox;
			}
		}
		// Paginations
		.pagination {
			.page-item {
				.page-link {
					&.rounded-circle {
						color: $dark-all-font-color !important;
					}
				}
			}
		}
		// Timeline
		.dark-timeline {
			.date-content {
				color: $dark-all-font-color !important;
				background-color: $dark-body-background !important;
			}
		}
		// grid 
		.grid-showcase {
			span {
				background-color: $dark-body-background;
			}
		}
		// forms
		.custom-input #validationCustom03.form-control,
		.custom-input #validationCustom03 .form-select,
		.custom-input #validationCustom05.form-control,
		.custom-input #validationCustom05 .form-select,
		.custom-input #validationCustomUsername.form-control,
		.custom-input #validationCustomUsername .form-select {
			border-color: $dark-body-background;
		}
		.form-control[type=file]:not(:disabled):not([readonly]) {
			color: $light-all-font-color;
			background-color: $dark-body-background;
			border-color: $dark-body-background;
		}
		.custom-input {
			.form-control,
			.form-select {
				background-color: $dark-body-background;
				border-color: $dark-body-background;
				color: $light-all-font-color;
			}
		}
		.vertical-main-wizard {
			.header-vertical-wizard {
				.nav-link {
					&:active,
					&.active {
						.vertical-wizard {
							.vertical-wizard-content {
								h6 {
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
			}
		}
		// Advance Init table
		td.highlight {
			background-color: $dark-datatable-odd !important;
		}
		// Base-input 
		.card-wrapper,
		.dark-field {
			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-body-background;
				color: $light-all-font-color;
			}
		}
		.form-control-sm {
			background-color: $dark-body-background;
			border-color: $dark-body-background;
		}
		.dark-inputs {
			.form-control {
				&.btn-square {
					background-color: $dark-body-background;
					border-color: $dark-body-background;
				}
				&.btn-pill {
					background-color: $dark-body-background;
					border-color: $dark-body-background;
				}
				&.input-air-primary {
					background-color: $dark-body-background;
					border-color: $dark-body-background;
				}
			}
		}
		.horizontal-wizard-wrapper {
			.main-horizontal-header {
				.horizontal-options {
					.nav-link {
						.horizontal-wizard {
							.stroke-icon-wizard {
								color: $dark-body-background;
							}
						}
					}
				}
			}
		}
		// Touchspin
		.touchspin-wrapper,
		.pre-post-touchspin {
			input[type="number"] {
				&.input-touchspin {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
			}
		}
		// unique checkbox checked
		.variation-box {
			.selection-box {
				input {
					&[type=checkbox]:checked~div {
						color: $bg-dark-color;
					}
				}
			}
		}
		// datepicker
		.flatpickr-calendar {
			box-shadow: unset;
			&.animate {
				background-color: $dark-card-background;
			}
			.flatpickr-day {
				color: $dark-all-font-color;
				&:hover {
					color: $dark-body-background;
				}
			}
		}
		.flatpickr-time {
			border: 1px solid $dark-card-inbox;
			input {
				background: $dark-datatable-sorting-even;
				color: rgba($white, 0.6);
			}
			.flatpickr-am-pm {
				&:hover {
					background: $dark-body-background;
					color: rgba($white, 0.6);
				}
			}
		}
		.main-inline-calender {
			&.input-group {
				.flatpickr-rContainer {
					.flatpickr-days {
						.dayContainer {
							.flatpickr-day {
								&:hover {
									background-color: $dark-card-background;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
		}
		// input-group
		.input-group-wrapper {
			.input-group {
				.dropdown-menu {
					li {
						a {
							background-color: $bg-dark-color;
							border-top: 1px solid $dark-body-background;
							color: $dark-small-font-color;
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
				}
			}
		}
		// Select2 
		.tagify__dropdown__item--active {
			background-color: $dark-body-background;
		}
		.tagify__input {
			color: $dark-small-font-color;
			&::before {
				color: $dark-small-font-color;
			}
		}
		.tagify {
			border-color: $dark-card-border;
			--tags-focus-border-color: #374558;
			&.tagify--select {
				.tagify__input {
					color: $dark-body-background;
				}
			}
		}
		.language-header {
			.js-languageSelect {
				border-color: $dark-card-border;
				color: $dark-all-font-color;
				option {
					background-color: $dark-card-border;
				}
				&:focus-visible,
				&:focus {
					outline-color: transparent;
				}
			}
		}
		.international-num {
			input {
				border-color: $dark-card-border;
				background-color: $dark-card-background;
				color: $dark-all-font-color;
				&:focus-visible {
					outline: $dark-card-border;
				}
			}
			.results {
				ul {
					border: 1px solid $dark-card-border;
				}
			}
		}
		.iti__country-list {
			background-color: $dark-card-background;
		}
		.select-box {
			.selection-option {
				&:hover {
					background-color: $dark-datatable-sorting-even;
				}
			}
			.options-container.active {
				~ {
					.search-box {
						input {
							background-color: $dark-all-font-color;
						}
					}
				}
			}
		}
		// Tables
		.table-hover {
			>tbody {
				>tr:hover {
					--bs-table-accent-bg: #1d1e26;
				}
			}
		}
		// Authentication
		.main-qr-code {
			.modal-toggle-wrapper {
				.modal-img {
					.qr-content {
						.alert {
							background-color: $dark-body-background;
							border-color: $dark-card-inbox;
							i {
								color: $dark-all-font-color;
							}
							div {
								color: $dark-all-font-color;
							}
						}
					}
				}
				form {
					.form-control {
						background-color: $dark-body-background;
						border-color: $dark-card-inbox;
					}
				}
			}
		}
		.authentication-options {
			.radio-wrapper {
				li {
					.form-check-label {
						span {
							>span {
								color: $dark-small-font-color;
							}
						}
					}
				}
			}
		}
		// Typeahead
		.typeahead-wrapper {
			.theme-form {
				.form-control {
					background-color: $dark-body-background !important;
					border-color: $dark-body-background !important;
				}
			}
		}
		// Dropzone
		.filepond--root {
			.filepond--drip {
				background-color: $dark-card-inbox;
				opacity: 1;
			}
			.filepond--drop-label {
				color: $dark-all-font-color;
			}
		}
		// carousel
		.carousel-dark {
			.carousel-caption {
				h3 {
					color: $bg-dark-color;
				}
			}
		}
		// Letter_box
		.compose-modal {
			form {
				.form-control {
					background-color: $dark-body-background;
					border-color: $dark-card-border;
					color: $dark-small-font-color;
				}
			}
		}
		.toolbar-box {
			.ql-snow {
				.ql-stroke {
					stroke: $dark-small-font-color;
				}
				.ql-tooltip {
					background-color: $dark-body-background;
					box-shadow: unset;
					border: 0;
					&.ql-editing {
						input[type=text] {
							background-color: $dark-card-background;
							border: 0;
							color: $dark-small-font-color;
						}
					}
				}
				a {
					color: $light-all-font-color;
				}
			}
			.ql-editor.ql-blank::before {
				color: $light-all-font-color;
			}
		}
		.email-body {
			.mail-header-wrapper {
				.mail-body {
					.mail-search {
						.form-control {
							background-color: $dark-body-background;
							border: 0;
							color: $light-all-font-color;
							&::placeholder {
								color: $light-all-font-color;
							}
						}
						i {
							color: $light-all-font-color;
						}
					}
					.dropdown-menu {
						.dropdown-item {
							background: $dark-body-background;
							opacity: 1;
							border: 0;
							color: $light-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
				}
			}
		}
		.inbox-options {
			.light-square {
				&.btn-group {
					.dropdown-menu {
						background: $dark-editor-document;
						.dropdown-item {
							&:hover {
								background-color: $light-all-font-color;
								color: $dark-small-font-color;
							}
						}
					}
				}
			}
		}
		.inbox-security {
			background-color: $dark-body-background;
		}
		.mail-pagination {
			.pagination-number,
			.pagination-button {
				background-color: $dark-card-inbox;
			}
		}
		// dropdown
		.btn-group {
			.dropdown-menu {
				li {
					a {
						background-color: $bg-dark-color;
						border-top: 1px solid $dark-body-background;
						color: $dark-small-font-color;
						&:hover {
							background-color: $dark-card-border;
						}
					}
				}
			}
			.form-wrapper {
				background-color: $dark-card-background;
			}
		}
		.dropdown-block {
			&.dark-input-type {
				.input-group {
					background-color: $dark-body-background;
					opacity: 1;
					&:hover {
						background-color: $dark-card-background;
					}
				}
			}
		}
		// Add-product 
		.flatpicker-calender {
			&.product-date {
				.form-control {
					&.flatpickr-input {
						border: unset;
						background-color: $dark-body-background;
					}
				}
			}
		}
		.price-wrapper {
			.radio-wrapper {
				li {
					.form-check-input {
						border: 1px solid $dark-card-border;
						~.form-check-label {
							span {
								color: $dark-small-font-color;
							}
						}
					}
				}
			}
		}
		.product-buttons {
			.btn {
				&:hover,
				&:active {
					color: $dark-all-font-color;
				}
				svg {
					stroke: $dark-all-font-color;
				}
			}
		}
		.ql-formats {
			.ql-picker-label {
				&::before {
					color: $dark-all-font-color;
				}
			}
		}
		// list-product
		.list-product {
			.datatable-pagination {
				.datatable-active {
					.datatable-pagination-list-item-link {
						background-color: var(--theme-default);
						color: var(--white);
					}
				}
				.datatable-pagination-list-item-link {
					background-color: $dark-body-background;
					color: $dark-small-font-color;
				}
			}
			.datatable-input {
				&:focus {
					outline: unset;
				}
			}
			.datatable-selector {
				color: $dark-all-font-color;
				background-color: $dark-body-background;
			}
		}
		.list-product-header {
			.collapse {
				.list-product-body {
					border: unset !important;
				}
			}
		}
		// tables
		.table {
			--bs-table-bg: trasparnt;
		}
		// Alert
		.light-alert {
			.alert-light-primary {
				color: $dark-card-inbox;
			}
		}
		// private-chat
		.left-sidebar-wrapper {
			.left-sidebar-chat {
				.input-group {
					.input-group-text {
						background-color: $dark-body-background;
					}
					.form-control {
						background-color: $dark-body-background;
						border: unset;
						color: $dark-all-font-color;
						&::placeholder {
							color: $dark-all-font-color;
						}
					}
				}
			}
			.advance-options {
				.chats-user {
					span {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.search-contacts {
			.form-control {
				background-color: $dark-body-background;
				border-color: $dark-card-border;
				color: $light-all-font-color;
				&::placeholder {
					color: $dark-small-font-color;
				}
			}
		}
		.right-sidebar-title {
			span {
				color: $dark-all-font-color;
			}
		}
		.right-sidebar-Chats {
			.msger-chat {
				background: $dark-card-inbox;
			}
			.msger-inputarea {
				background: $dark-card-background;
			}
			.msger-input {
				&:focus {
					background-color: $dark-body-background;
				}
			}
		}
		.fg-emoji-picker-search {
			input,
			.fg-emoji-picker-search-icon {
				background-color: $dark-body-background;
				color: $light-all-font-color;
			}
		}
		.fg-emoji-picker-category-title {
			background-color: $dark-card-inbox;
		}
		// Add-post 
		.add-post {
			form {
				label,
				.col-form-label {
					color: $dark-small-font-color;
				}
				.form-control {
					background-color: $dark-body-background;
					color: $light-all-font-color;
					border: unset;
					&::placeholder {
						color: $light-all-font-color;
					}
				}
			}
			.dropzone {
				&.dz-clickable {
					h6 {
						color: $dark-body-background;
					}
				}
			}
		}
		// dashboard-2 
		.sales-overview {
			background-blend-mode: overlay;
			background-color: $dark-card-background;
		}
		.deal-card {
			background-blend-mode: color-burn;
		}
		.ql-container {
			&.ql-snow {
				.ql-editor {
					color: $light-all-font-color;
				}
			}
		}
		.blog-box.blog-shadow {
			.blog-details {
				p{
					color: rgba(255, 255, 255, 0.6);
				}
				h4 {
					color: rgba(255, 255, 255, 0.6);
				}
				.blog-social{
					li{
						color: rgba(255, 255, 255, 0.6);
					}
				}
			}
		}
		.flatpickr-current-month {
			.flatpickr-monthDropdown-months {
				color: rgba(255, 255, 255, 0.6) !important;
			}
		}
		span.flatpickr-weekday{
			color: rgba(255, 255, 255, 0.6) !important;
		}
		.flatpickr-current-month input.cur-year{
			color: rgba(255, 255, 255, 0.6) !important;
		}
		.flatpickr-months {
			.flatpickr-prev-month {
				color: rgba(255, 255, 255, 0.6);
				fill: rgba(255, 255, 255, 0.6);
			}
			.flatpickr-next-month {
				color: rgba(255, 255, 255, 0.6);
				fill: rgba(255, 255, 255, 0.6);
			}
		}
		.touchspin-wrapper{
			.btn-touchspin{
				&.spin-border-dark{
					color: $white;
				}
			}
		}
		.light-table-bg {
			.light-card {
				background-color: #F2F3F7 !important;
				td{
					color: $black !important;
				}
				th{
					color: $black !important;
				}
			}
		}
		.table{
			.alert{
			  &.inverse{
				background-color: rgba(0, 157, 181, 0.8) !important;
			  }
			}
		  }
		  .text-color-card{
			.txt-dark{
				color: $white !important;
			}
		  }
		.modal-content{
			.modal-header{
				.modal-title{
					color: $white !important;
				}
			}
		}
		.breadcrumb-item.active {
			color: var(--body-font-color);
		}
		.breadcrumb-item {
			+ {
				.breadcrumb-item {
					&::before {
						color: var(--body-font-color);
					}
				}
			}
		}
		.ql-snow .ql-picker-options{
			background-color: $dark-body-background;
			border-color: $dark-body-background !important;
			.ql-picker-item{
				color: var(--body-font-color);
			}
		}
		.project-datatable {
			.datatable-table {
				>tbody {
					>tr {
						>td {
							a {
								color: var(--body-font-color);
							}
						}
					}
				}
			}
		}
		.blog-box {
			.blog-details {
				.single-blog-content-top {
					border-color: var(--sidebar-border-color);
				}
			}
		}
		.job-search {
			.job-description {
				.theme-form {
					.row {
						.col {
							.form-control {
								border-color: $dark-body-background;
							}
						}
					}
				}
			}
		}
		.login-card{
			.text-muted{
				color:  var(--body-font-color) !important;
			  }
		}
	}
	&.dark-sidebar {
		.toggle-sidebar {
			svg {
				stroke: var(--theme-default);
			}
		}
		.page-wrapper {
			.sidebar-main-title {
				p {
					color: $dark-all-font-color;
				}
			}
			&.compact-wrapper {
				.page-body-wrapper {
					div.sidebar-wrapper {
						background-color: $dark-card-background;
						.sidebar-main {
							.sidebar-links {
								li {
									a {
										span {
											color: $dark-all-font-color;
										}
										svg {
											stroke: $dark-all-font-color;
										}
									}
								}
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													.sidebar-link {
														&.active {
															svg {
																color: var(--theme-default);
																stroke: var(--theme-default);
															}
														}
													}
													.mega-menu-container {
														.mega-box {
															.link-section {
																.submenu-title {
																	h5 {
																		color: $dark-all-font-color;
																	}
																}
																.submenu-content {
																	&.opensubmegamenu {
																		li {
																			a {
																				color: $dark-all-font-color;
																				font-weight: 400;
																			}
																		}
																	}
																}
															}
														}
														&::after {
															background-color: $light-all-font-color;
														}
													}
													.sidebar-submenu {
														li {
															a {
																color: $dark-all-font-color;
															}
														}
													}
												}
											}
										}
									}
								}
								.sidebar-list {
									ul.sidebar-submenu {
										li {
											a {
												span {
													color: $dark-all-font-color;
												}
												&.active {
													color: var(--theme-default);
												}
											}
										}
									}
								}
							}
						}
					}
					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}
			&.horizontal-wrapper {
				.page-header {
					background-color: $dark-card-background;
				}
				.page-body-wrapper {
					.sidebar-wrapper {
						background: $dark-card-background;
						.sidebar-main {
							background-color: $dark-card-background;
							.sidebar-links {
								.simplebar-wrapper {
									.simplebar-mask {
										.simplebar-content-wrapper {
											.simplebar-content {
												>li {
													>a {
														color: $dark-all-font-color;
													}
													.sidebar-submenu {
														background: $dark-card-background;
														li {
															a {
																color: $dark-all-font-color;
																&.active,
																&:hover {
																	color: var(--theme-default);
																}
															}
															.nav-sub-childmenu {
																background: $dark-card-background;
															}
														}
													}
													.mega-menu-container {
														background: $dark-card-background;
														.mega-box {
															.link-section {
																.submenu-content {
																	li {
																		a {
																			color: $dark-all-font-color;
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
							.left-arrow,
							.right-arrow {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
			&.compact-sidebar {
				.page-body-wrapper {
					div {
						&.sidebar-wrapper {
							>div {
								background: $dark-card-background;
							}
							.sidebar-main {
								.sidebar-links {
									li {
										.sidebar-title {
											border-bottom: 1px solid $dark-card-border;
										}
										a {
											span {
												color: $dark-all-font-color;
											}
											svg {
												stroke: $dark-all-font-color;
											}
										}
										.sidebar-submenu,
										.mega-menu-container {
											background-color: $dark-card-background;
											li {
												a {
													border-bottom: 1px solid $dark-card-border;
												}
											}
										}
									}
									.simplebar-wrapper {
										.simplebar-mask {
											.simplebar-content-wrapper {
												.simplebar-content {
													>li {
														.sidebar-link {
															&.active {
																background-color: rgba($primary-color, 0.2);
																span {
																	color: var(--theme-default);
																}
																svg {
																	color: var(--theme-default);
																	stroke: var(--theme-default);
																}
															}
														}
														.mega-menu-container {
															.mega-box {
																.link-section {
																	.submenu-title {
																		h5 {
																			color: $dark-all-font-color;
																		}
																	}
																	.submenu-content {
																		&.opensubmegamenu {
																			li {
																				a {
																					color: $dark-all-font-color;
																					font-weight: 400;
																				}
																			}
																		}
																	}
																}
															}
															&::after {
																background-color: $light-all-font-color;
															}
														}
														.sidebar-submenu {
															li {
																a {
																	color: $dark-all-font-color;
																}
															}
														}
													}
												}
											}
										}
									}
									.sidebar-list {
										ul.sidebar-submenu {
											li {
												a {
													span {
														color: $dark-all-font-color;
													}
													&.active {
														color: var(--theme-default);
													}
												}
											}
										}
									}
								}
							}
						}
					}
					.according-menu {
						i {
							color: $dark-all-font-color;
						}
					}
				}
			}
		}
	}
	.for-dark {
		display: none;
	}
	&[class*='dark-'] {
		.for-dark {
			display: block;
		}
		.for-light {
			display: none;
		}
	}
}
// =======================  Dark Body only  ======================= //
/**=====================
	4.1. Dark CSS ends
==========================**/