/**=====================
  5.3 Header CSS Start
==========================**/
.cart-empty {
  display: none;
  text-align: center;
  padding: 20px;
  @media (max-width: 575px) {
    padding: 15px;
  }
  h5 {
    margin-top: 15px;
  }
  .cart-image {
    width: calc(85px + 55 * (100vw - 1400px) / 520);
    height: calc(85px + 55 * (100vw - 1400px) / 520);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--light-slate);
    margin: 0 auto;
  }
  &.show {
    display: block;
  }
}
/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.offcanvas,
.offcanvas-bookmark {
  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        position: relative;
        filter: blur(5px) grayscale(100%);
      }
    }
  }
}
.offcanvas {
  position: unset;
  visibility: visible;
  background: unset;
  z-index: 2;
}
.logo-wrapper {
  .toggle-sidebar {
    position: absolute;
    right: 30px;
    top: 22px;
    cursor: pointer;
    [dir="rtl"] & {
      right: unset;
      left: 30px;
    }
    svg {
      width: $header-wrapper-nav-icon-size;
      height: $header-wrapper-nav-icon-size;
      stroke: var(--body-light-font-color);
    }
    &:before {
      content: "";
      position: absolute;
      height: 40px;
      width: 40px;
      background-color: rgba($primary-color, 0.1);
      border-radius: 100%;
      left: -11px;
      z-index: -2;
      top: -8px;
      transform: scale(0);
      transition: all 0.3s ease;
      [dir="rtl"] & {
        left: unset;
        right: -11px;
      }
    }
    &:hover {
      &:before {
        height: 40px;
        width: 40px;
        transform: scale(1);
        transition: all 0.3s ease;
      }
      >svg {
        color: var(--theme-default) !important;
        stroke: var(--theme-default) !important;
      }
    }
  }
}
.header-wrapper {
  position: fixed;
  width: 50%;
  left: 0;
  justify-content: flex-start;
  [dir="rtl"] & {
    left: unset;
    right: 0;
  }
}
.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
// header breadcrumb
.page-header {
  .col-auto {
    display: flex;
    align-items: center;
    padding-left: 25px;
    z-index: 1;
    [dir="rtl"] & {
      padding-left: 12px;
      padding-right: 25px;
    }
    @media (max-width: 767px) {
      position: fixed;
      width: 83px;
      left: 0;
      justify-content: flex-start;
      z-index: 4;
      padding: 12px 25px;
      top: 12px;
      [dir="rtl"] & {
        left: unset;
        right: 0;
      }
    }
    @media (max-width:575px) {
      top: 0;
      height: 65px;
      padding: 12px 15px;
      width: 100px;
    }
    &.page-title {
      @media (max-width:767px) {
        position: absolute !important;
        left: 0px;
        top: 75px;
        width: 100%;
        display: block !important;
        background-color: var(--white);
        box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
        z-index: 0;
        [dir="rtl"] & {
          left: unset;
          right: 0px;
        }
      }
      @media (max-width:575px) {
        top: 65px;
      }
    }
    &.nav-right {
      @media (max-width:767px) {
        display: inline-block;
        right: 0;
        position: unset;
      }
      @media (max-width:575px) {
        height: unset;
      }
    }
  }
  .toggle-sidebar {
    width: 33px;
    height: 33px;
    box-shadow: 0px 7.7982px 17.3293px rgba(0, 157, 181, 0.07);
    border-radius: 2px;
    background-color: var(--white);
    cursor: pointer;
    display: none;
    svg {
      width: $header-wrapper-nav-icon-size;
      height: $header-wrapper-nav-icon-size;
      stroke: var(--body-light-font-color);
    }
    &:hover {
      >svg {
        color: var(--theme-default) !important;
        stroke: var(--theme-default) !important;
      }
    }
    @media (max-width: 991px){
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }
    @media (max-width:479px) {
      width: 30px;
      height: 30px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}
.header-right-wrapper {
  &.page-title {
    ol {
      gap: 8px;
      .breadcrumb-item {
        position: relative;
        a {
          font-size: 14px;
          font-weight: 500;
          color: var(--body-light-font-color);
        }
        +.breadcrumb-item {
          &::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: var(--body-light-font-color);
            padding-right: 0;
            bottom: 8px;
            position: absolute;
            left: -3px;
            [dir="rtl"] & {
              left: unset;
              right: -3px;
              padding-right: unset;
              padding-left: 0;
            }
          }
        }
        &:nth-child(2) {
          color: var(--body-light-font-color);
        }
        &:nth-child(3) {
          color: var(--body-font-color);
        }
      }
    }
  }
}
.page-wrapper{
  &.compact-wrapper{
    .header-left-wrapper.col-auto {
      @media (min-width: 992px){
        padding: 0 !important;
      }
    }
  }
}
/**======Main Header css Ends ======**/
/**=====================
    5.3 Header CSS Ends
==========================**/